import React, { useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { deviceSizeNumber, windowMaxWidth } from '../../components/ui/Layout/breakpoints';
import layoutActions from '../../redux/layout/actions';

const MainWrapper = styled.div`
  background-color: var(--secondary-whisper);
  font-family: var(--font-family-regular);
  font-weight: var(--font-weight-regular);
  height: 100vh;

  @supports (height: 100lvh;) {
    height: 100lvh;
  }

  margin-top: 0;
  position: relative;

  .page-with-header {
    display: flex;
    flex-direction: column;
    height: 100%;
    background-color: var(--secondary-whisper);
  }

  .main-layout {
    margin-bottom: 30px;
    min-height: 0;
  }

  .page-wrapper {
    box-sizing: content-box;
    display: flex;
    flex-direction: column;
    overflow: auto;
    scrollbar-color: rgba(136, 136, 136, 0.3) transparent;
    scrollbar-width: thin;
    position: relative;
    align-items: stretch;
  }

  .page-content {
    max-width: 1840px;
    height: calc(100vh - 60px);
    margin: 0 auto;
    width: 100%;
    overflow: auto;

    @supports (height: 100dvh) {
      height: calc(100dvh - 60px);
    }

    @media (min-height: 1000px) {
      overflow-y: hidden;
    }

    @media (${windowMaxWidth.splitScreen}) {
      overflow-y: auto;
    }
  }

  .ant-popover {
    z-index: 999;
  }
`;

const StyledMainWrapper = ({ setSplitscreenMode, ...rest }) => {
  useEffect(() => {
    const initialSplitScreenMode = window.innerWidth <= deviceSizeNumber.splitScreen;
    setSplitscreenMode(initialSplitScreenMode);
    // a listener that updates the windowWidth state variable
    const updateSplitScreenMode = () => {
      const _isSplitScreen = window.innerWidth <= deviceSizeNumber.splitScreen;
      setSplitscreenMode(_isSplitScreen);
    };
    window.addEventListener('resize', updateSplitScreenMode);

    // clean up function
    return () => window.removeEventListener('resize', updateSplitScreenMode);
  }, []);

  return <MainWrapper {...rest} />;
};

const { setSplitscreenMode } = layoutActions;

const mapDispatchToProps = {
  setSplitscreenMode
};

export default connect(null, mapDispatchToProps)(StyledMainWrapper);
