import { all, call, put, takeEvery, select } from 'redux-saga/effects';
import actions from './actions';
import { onUploadFile } from './apiCalls';
import worklistClaimsActions from '../worklistClaims/actions';

const getUploadsTempContainer = (state) => state.FileUpload.get('uploadsTempContainer');

export function* uploadFileSaga({ file, file_container_id, callback }) {
  try {
    const upload = yield call(onUploadFile, file, file_container_id);
    if (upload.data) {
      const uploadsTempContainer = yield select(getUploadsTempContainer);
      const _uploadsTempContainer = [...uploadsTempContainer, upload.data];
      yield all([put(actions.uploadFileSuccess()), put(actions.setUploadsTempContainer(_uploadsTempContainer))]);

      if (callback && typeof callback === 'function') {
        callback(upload.data);
      }
    } else {
      yield put(actions.uploadFileError());
    }
  } catch (error) {
    yield put(actions.uploadFileError(error));
  }
}

export function* uploadFileRequestForMessageAttachmentsSaga({ file, attachmentId, attachmentType }) {
  try {
    // file container id is not needed for message attachments
    const file_container_id = '';
    const upload = yield call(onUploadFile, file, file_container_id);
    if (upload.data) {
      yield put(actions.uploadFileRequestForMessageAttachmentsSuccess(upload.data));
      const newMessageAttachemnt = {
        attachment_id: attachmentId,
        attachment_type: attachmentType,
        blob: upload.data
      };
      const newMessageAttachments = yield select(getNewMessageAttachments);
      const updatedMessageAttachments = [...newMessageAttachments, newMessageAttachemnt];
      yield put(worklistClaimsActions.setNewMessageAttachments(updatedMessageAttachments));
    } else {
      yield put(actions.uploadFileRequestForMessageAttachmentsError());
    }
  } catch (error) {
    yield put(actions.uploadFileRequestForMessageAttachmentsError());
  }
}

const getNewMessageAttachments = (state) => state.WorklistClaims.get('newMessageAttachments');

export default function* rootSaga() {
  yield all([
    takeEvery(actions.UPLOAD_FILE, uploadFileSaga),
    takeEvery(actions.UPLOAD_FILE_REQUEST_FOR_MESSAGE_ATTACHMENTS, uploadFileRequestForMessageAttachmentsSaga)
  ]);
}
