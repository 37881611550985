import styled from 'styled-components';

// Height of the page header
const IMPORT_HEADER_HEIGHT = 100;
// Height of the page bottom margin
const IMPORT_BOTTOM_MARGIN_HEIGHT = 20;
// Height of the table header (column names)
const IMPORT_TABLE_COLUMN_HEADER_HEIGHT = 41;
const IMPORT_DOWNLOAD_TEMPLATE_WRAPPER_HEIGHT = 60;

const IMPORT_HEADER_AND_MARGIN_HEIGHT = IMPORT_HEADER_HEIGHT + IMPORT_BOTTOM_MARGIN_HEIGHT;
// Height of the page header, bottom margin and the top and bottom border of the table
// Used to calculate the table content height
const IMPORT_HEADER_AND_MARGIN_HEIGHT_AND_BORDER_HEIGHT = IMPORT_HEADER_AND_MARGIN_HEIGHT + 2;
// Height of the page header, bottom margin, top and botton border of the table and the table header
// Used to calculate the table body height
const IMPORT_HEADER_AND_MARGIN_AND_TABLE_HEADER_HEIGHT =
  IMPORT_HEADER_AND_MARGIN_HEIGHT_AND_BORDER_HEIGHT + IMPORT_TABLE_COLUMN_HEADER_HEIGHT;

const ImportMainWrapper = styled.div`
  background-color: var(--secondary-whisper);
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  padding: 0 20px;
  overflow: auto;
  width: 100%;

  .import-inner-wrapper {
    width: 100%;
    max-width: 1840px;
  }
`;

const ImportHeaderWrapper = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 100%;
`;

const ImportSectionWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
`;

const ImportHeaderButtonsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  gap: 10px;
`;

const ImportUploaderSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${IMPORT_HEADER_AND_MARGIN_HEIGHT}px);
  width: 100%;
`;

const ImportLoadingStateWrapper = styled.div`
  border-radius: var(--border-radius-medium);
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: calc(100vh - ${IMPORT_HEADER_AND_MARGIN_HEIGHT}px);
  width: 100%;
  background-color: var(--greyscale-white);
`;

const ImportUploaderDropzoneWrapper = styled.div`
  align-items: center;
  background-color: var(--greyscale-white);
  border: 2px dashed var(--primary-oqio-blue);
  border-radius: var(--border-radius-small);
  display: flex;
  height: calc(100% - ${IMPORT_DOWNLOAD_TEMPLATE_WRAPPER_HEIGHT}px);
  width: 100%;
  justify-content: center;

  &:hover {
    cursor: pointer;
  }
`;

const ImportUploaderTextWrapper = styled.div`
  display: flex;
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-small);
`;

const ImportUploaderText = styled.span`
  color: var(--greyscale-blackish);
`;

const ImportUploaderHighlightedText = styled.span`
  color: var(--primary-oqio-blue);
  margin-left: 4px;

  &:hover {
    text-decoration: underline;
  }
`;

const ImportTemplateButtonWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  height: ${IMPORT_DOWNLOAD_TEMPLATE_WRAPPER_HEIGHT}px;
  justify-content: flex-start;
  width: 100%;

  .ant-btn-text {
    button {
      padding: 0;
    }
    &:hover {
      background: none !important;
    }
  }
`;

const ImportTableWrapper = styled.div`
  background-color: var(--greyscale-white);
  border: 1px solid var(--greyscale-dark-white);
  border-radius: var(--border-radius-medium);
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - ${IMPORT_HEADER_AND_MARGIN_HEIGHT}px);
  min-width: 100%;

  .ant-table-wrapper {
    height: calc(100vh - ${IMPORT_HEADER_AND_MARGIN_HEIGHT_AND_BORDER_HEIGHT}px);
  }

  .ant-table {
    &.ant-table-bordered > .ant-table-container {
      border: none;
    }
    .ant-table-container {
      .ant-table-thead {
        th {
          font-size: var(--font-size-small);
          color: var(--greyscale-shade);
          height: ${IMPORT_TABLE_COLUMN_HEADER_HEIGHT}px;
          line-height: var(--font-line-height-regular);
          border-color: var(--greyscale-dark-white) !important;
          border-inline-end: none !important;

          &.status-column {
            border-inline-end: 1px solid var(--greyscale-dark-white) !important;
          }

          &.invalid-column {
            background-color: var(--greyscale-porcelain);
            color: var(--greyscale-concrete);
          }

          &:first-child {
            padding-left: 10px;
          }
        }
      }

      .ant-table-tbody {
        height: calc(100vh - ${IMPORT_HEADER_AND_MARGIN_AND_TABLE_HEADER_HEIGHT}px);
        .ant-table-cell {
          padding: 10px 10px;
          height: 41px;
          border-inline-end: none !important;
          line-height: var(--font-line-height-regular);

          &.status-column {
            display: flex;
            text-align: left !important;
            border-inline-end: 1px solid var(--greyscale-dark-white) !important;
          }

          &.invalid-column {
            background-color: var(--greyscale-porcelain);
            color: var(--greyscale-concrete);
          }

          &:first-child {
            padding-left: 10px;
          }

          &:hover {
          }
        }

        .ant-table-tbody-virtual-scrollbar {
          &.ant-table-tbody-virtual-scrollbar-horizontal {
            visibility: visible !important;
          }

          .ant-table-tbody-virtual-scrollbar-thumb {
            background: rgba(136, 136, 136, 0.3) !important;
          }
        }
      }
    }
  }
`;

const ImportTableStatusTag = styled.div`
  color: ${(props) => props.$color};
  display: flex;
  align-items: center;
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-semi-bold);
  line-height: var(--font-line-height-small);
  max-width: 220px;
  overflow: hidden;
  margin-right: 3px;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

const ImportTableStatusCircle = styled.div`
  background-color: ${(props) => props.$color};
  border-radius: 50%;
  display: inline-block;
  height: 15px;
  margin-right: 5px;
  width: 15px;
`;

const ImportTableStatusLabel = styled.span``;

const ImportTableStatusTooltipMessageList = styled.ul`
  padding: 0;
  margin: 0;
`;

const ImportTableStatusTooltipMessage = styled.div`
  color: var(--primary-oqio-red);
  font-size: var(--font-size-small);
`;

const ImportSummaryWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
`;

const ImportSummaryRow = styled.div`
  display: flex;
  margin-bottom: 2px;
  flex-direction: row;
  align-items: center;

  &:last-child {
    margin-bottom: 0;
  }
`;

const ImportSummaryIcon = styled.div`
  margin-right: 5px;
  display: flex;

  svg {
    path {
      stroke: var(--primary-oqio-blue);
    }
  }
`;

const ImportSummaryHeader = styled.span`
  font-style: italic;
  margin-bottom: 20px;
  width: 100%;
`;

const ImportSummaryText = styled.span`
  line-height: var(--font-line-height-regular);
`;

const ImportSummaryHighlightedText = styled.span`
  margin-right: 5px;
  font-weight: var(--font-weight-semi-bold);
  color: var(--primary-oqio-blue);
  line-height: var(--font-line-height-regular);
`;

export {
  ImportMainWrapper,
  ImportHeaderWrapper,
  ImportSectionWrapper,
  ImportHeaderButtonsWrapper,
  ImportUploaderSectionWrapper,
  ImportLoadingStateWrapper,
  ImportUploaderDropzoneWrapper,
  ImportUploaderTextWrapper,
  ImportUploaderText,
  ImportUploaderHighlightedText,
  ImportTemplateButtonWrapper,
  ImportTableStatusTag,
  ImportTableWrapper,
  ImportTableStatusCircle,
  ImportTableStatusLabel,
  ImportTableStatusTooltipMessageList,
  ImportTableStatusTooltipMessage,
  ImportSummaryWrapper,
  ImportSummaryRow,
  ImportSummaryIcon,
  ImportSummaryHeader,
  ImportSummaryText,
  ImportSummaryHighlightedText
};
