import styled from 'styled-components';
import { Heading2, Heading3 } from '../../Heading/Heading';
import { windowMaxWidth } from '../../Layout/breakpoints';

// Modal header elements
const HeaderWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const HeaderTitle = styled(Heading2)`
  margin: 0;
`;

const HeaderSubTitle = styled.span`
  color: var(--greyscale-shade);
  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  margin-bottom: 4px;
`;

// Modal footer elements
const StyledFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;

// Modal form layout elements
const FormStepContentWrapper = styled.div`
  padding: 0;
`;

const GridRow = styled.div`
  display: grid;
  column-gap: 20px;
  grid-auto-flow: column;
  grid-template-columns: ${(props) => (props.columns ? props.columns : 'auto')};

  .ant-row.ant-form-item {
    width: 100%;
  }

  @media (${windowMaxWidth.mobile}) {
    grid-template-columns: auto;
    grid-auto-flow: row;
  }
`;

const FormGroup = styled.div`
  border-bottom: ${(props) => (props.$lastGroup ? 'none' : '1px solid #e8e8e8')};
  display: flex;
  flex-direction: column;
  padding: 20px 40px;
`;

const FormGroupHalfSize = styled.div`
  width: calc(50% - 10px);

  @media (${windowMaxWidth.mobile}) {
    width: 100%;
  }
`;

const FormGroupTitle = styled(Heading3)`
  margin-bottom: 20px;
  margin-top: 0;
`;

const FormGroupDivider = styled.div`
  border-bottom: 1px solid #e8e8e8;
  margin: 10px 0 20px;
`;

const BuildingsCreateEditModalLayout = {
  HeaderWrapper,
  HeaderTitle,
  HeaderSubTitle,
  StyledFooter,
  FormStepContentWrapper,
  GridRow,
  FormGroup,
  FormGroupHalfSize,
  FormGroupTitle,
  FormGroupDivider
};

export default BuildingsCreateEditModalLayout;
