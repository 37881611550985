import React from 'react';
import AntButton from 'antd/es/button';
import styled from 'styled-components';
import { windowMaxWidth } from '../../ui/Layout/breakpoints';

const BUTTON_OPTIONS = {
  default: {
    padding: '10px 20px'
  },
  iconOnlyButton: {
    padding: '0 8px'
  },
  hasIcon: {
    padding: '0 20px 0 15px'
  }
};

const BUTTON_TYPES = {
  primary: {
    activeBackgroundColor: 'var(--primary-oqio-blue)',
    backgroundColor: 'var(--primary-oqio-blue)',
    hoverBackgroundColor: 'var(--primary-oqio-blue-hover)',
    color: 'var(--greyscale-white)',
    hoverFontColor: 'var(--greyscale-white)',
    border: '1px solid var(--primary-oqio-blue)',
    hoverBorderColor: '1px solid  var(--primary-oqio-blue-hover)'
  },
  secondary: {
    activeBackgroundColor: 'var(--greyscale-white)',
    backgroundColor: 'var(--greyscale-white)',
    hoverBackgroundColor: 'var(--primary-oqio-blue-hover-transparent)',
    color: 'var(--primary-oqio-blue)',
    hoverFontColor: 'var(--primary-oqio-blue)',
    border: '2px solid var(--primary-oqio-blue)',
    hoverBorderColor: '2px solid var(--primary-oqio-blue)'
  },
  disabled: {
    activeBackgroundColor: 'var(--greyscale-porcelain)',
    backgroundColor: 'var(--greyscale-porcelain)',
    border: '1px solid var(--greyscale-dark-white)',
    color: 'var(--greyscale-light-grey)',
    cursor: 'not-allowed'
  },
  navigation: {
    activeBackgroundColor: 'var(--greyscale-white)',
    backgroundColor: 'transparent',
    hoverBackgroundColor: 'var(--primary-oqio-blue-hover-transparent)',
    color: 'var(--primary-oqio-blue)',
    hoverFontColor: 'var(--primary-oqio-blue)',
    border: '2px solid var(--primary-oqio-blue)',
    hoverBorderColor: '2px solid var(--primary-oqio-blue)'
  }
};

const StyledButton = styled(AntButton)`
  align-items: center;
  background-color: ${(props) => BUTTON_TYPES[props.type]?.backgroundColor};
  border: ${(props) => (props.danger ? '1px solid var(--primary-oqio-red)' : BUTTON_TYPES[props.type]?.border)};
  border-radius: var(--border-radius-small);
  box-shadow: none !important;
  color: ${(props) => BUTTON_TYPES[props.type]?.color};
  cursor: pointer;
  display: inline-flex;
  font-weight: var(--font-weight-medium);
  justify-content: center;
  outline: inherit;
  padding: ${(props) =>
    !props.children
      ? BUTTON_OPTIONS.iconOnlyButton.padding
      : props.icon
        ? BUTTON_OPTIONS.hasIcon.padding
        : BUTTON_OPTIONS.default.padding};
  transition:
    color 0.2s,
    background-color 0.1s,
    border-color 0.2s;
  width: ${(props) => (props.fullWidth ? '100%' : 'auto')};

  @media ${windowMaxWidth.mobile} {
    padding: 10px 10px;
  }

  svg {
    path,
    circle {
      stroke: ${(props) => BUTTON_TYPES[props.type]?.color};
    }
    rect {
      fill: ${(props) => BUTTON_TYPES[props.type]?.color};
    }
  }

  &:active {
    background-color: ${(props) => BUTTON_TYPES[props.type]?.activeBackgroundColor};
  }

  &:focus {
    outline: none;
  }

  &:focus-visible {
    outline: 2px solid var(--primary-oqio-blue-hover);
    outline-offset: 2px;
    transition:
      outline-offset 0s,
      outline 0s;
  }

  &:hover {
    background-color: ${(props) => BUTTON_TYPES[props.type]?.hoverBackgroundColor};
    border: ${(props) =>
      props.danger ? '1px solid var(--primary-oqio-red-hover)' : BUTTON_TYPES[props.type]?.hoverBorderColor};
    color: ${(props) => BUTTON_TYPES[props.type]?.hoverFontColor};
  }

  &:disabled {
    background-color: var(--greyscale-porcelain);
    border: 1px solid var(--greyscale-dark-white);
    color: #b2b2b2;
    cursor: default;
  }

  .ant-btn-icon {
    width: 20px;
    height: 20px;
  }

  &.modal-header-button {
    border: 0px;
    position: relative;

    &:hover {
      background-color: 'var(--secondary-whisper)';
      border: 0px;
    }

    svg {
      path,
      circle {
        stroke: var(--greyscale-white);
      }
      rect {
        fill: #111;
      }
    }
  }

  &.btn {
    &-icon-only {
      padding: 8px;

      .ant-btn-icon {
        margin-right: 0px !important;
      }
    }

    &-worklist {
      position: relative;
      margin-left: 10px;

      &:hover {
        background-color: var(--primary-oqio-blue-hover-transparent);
      }

      @media ${windowMaxWidth.mobile} {
        width: 30px;
        height: 30px;
      }
    }

    &-claim-status {
      padding: 0 30px 0 25px;
      margin-right: 2px;

      @media ${windowMaxWidth.mobile} {
        display: flex;
        margin: 0;
        height: 30px;
        padding: 0;
        width: 30px;
        align-items: center;
        justify-content: center;
      }
    }

    &-dropdown-claim-status {
      display: flex;
      justify-content: flex-start;
    }

    &-icon-right {
      flex-direction: row-reverse;
      height: 20px;
      padding: 0;

      .ant-btn-icon {
        margin-left: 6px !important;
        margin-right: 0px !important;
      }
    }

    &-worklist-claim-message-attachment {
      color: var(--greyscale-shade);

      .ant-btn-icon {
        svg {
          path {
            stroke: var(--greyscale-shade);
          }
        }
      }
    }
  }

  > span:not(.ant-btn-icon) {
    @media ${windowMaxWidth.mobile} {
      display: ${(props) => (props.$hideTextOnMobile ? 'none' : 'inline-block')};
    }
  }

  &.ant-btn-link {
    border: none;
    color: var(--primary-oqio-blue);
    height: 20px;
    padding: 0;

    svg {
      path {
        stroke: var(--primary-oqio-blue);

        &.fill-path {
          fill: var(--primary-oqio-blue);
        }
      }

      circle {
        stroke: var(--primary-oqio-blue);
      }
    }

    &:not(:disabled):not(.ant-btn-disabled):hover {
      color: var(--primary-oqio-blue-hover);

      svg {
        path {
          stroke: var(--primary-oqio-blue-hover);
        }
        circle {
          stroke: var(--primary-oqio-blue-hover);
        }
      }
    }

    &.ant-btn-dangerous {
      border: none;
      color: var(--primary-oqio-red);

      svg {
        path {
          stroke: var(--primary-oqio-red);
        }
        circle {
          stroke: var(--primary-oqio-red);
        }
      }

      &:not(:disabled):not(.ant-btn-disabled):hover {
        color: var(--primary-oqio-red-hover);

        svg {
          path {
            stroke: var(--primary-oqio-red-hover);
          }
          circle {
            stroke: var(--primary-oqio-red-hover);
          }
        }
      }
    }

    &:disabled {
      background-color: transparent;
      color: var(--greyscale-concrete);
      cursor: initial;

      svg {
        path {
          stroke: var(--greyscale-concrete);
        }
        circle {
          stroke: var(--greyscale-concrete);
        }
      }
    }
  }

  &.ant-btn-text {
    color: var(--primary-oqio-blue);

    svg {
      path {
        stroke: var(--primary-oqio-blue);
      }
      circle {
        stroke: var(--primary-oqio-blue);
      }
      rect {
        stroke: var(--primary-oqio-blue);
      }
    }

    &:not(:disabled):not(.ant-btn-disabled):hover {
      color: var(--primary-oqio-blue);

      svg {
        path {
          stroke: var(--primary-oqio-blue);
        }
        circle {
          stroke: var(--primary-oqio-blue);
        }
        rect {
          stroke: var(--primary-oqio-blue);
        }
      }
    }

    &.ant-btn-dangerous {
      border: none;
      color: var(--primary-oqio-red);

      &:not(:disabled):not(.ant-btn-disabled):hover {
        background-color: var(--greyscale-porcelain);
        color: var(--primary-oqio-red);

        svg {
          path {
            stroke: var(--primary-oqio-red);
          }
        }
      }

      svg {
        path {
          stroke: var(--primary-oqio-red);
        }
      }
    }

    &.btn-low-height {
      height: 20px;
    }
  }

  &.btn-overlay {
    background-color: var(--greyscale-white);
    border: none;
    border-radius: var(--border-radius-extra-large);
    box-shadow: 0 1px 8px 0 rgba(17, 17, 17, 0.2) !important;
    pointer-events: all;

    &:hover {
      background-color: var(--greyscale-porcelain);
      border-radius: var(--border-radius-extra-large);
      color: 'var(--primary-oqio-blue)';

      svg {
        path {
          stroke: ${(props) => BUTTON_TYPES[props.type]?.color || 'var(--primary-oqio-blue)'};
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
        circle {
          stroke: ${(props) => BUTTON_TYPES[props.type]?.color || 'var(--primary-oqio-blue)'};
          transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
        }
      }
    }

    &.ant-btn[disabled] {
      background-color: var(--greyscale-white);
      border-radius: var(--border-radius-extra-large);
      box-shadow:
        0 0 0 1px rgba(0, 0, 0, 0.1),
        0 2px 4px rgba(0, 0, 0, 0.1) !important;

      svg {
        path {
          stroke: var(--greyscale-concrete);
        }
        circle {
          stroke: var(--greyscale-concrete);
        }
      }

      &:hover {
        background-color: var(--greyscale-white);
        border-radius: var(--border-radius-extra-large);
        box-shadow:
          0 0 0 1px rgba(0, 0, 0, 0.1),
          0 2px 4px rgba(0, 0, 0, 0.1) !important;
      }
    }
  }

  &.btn-action-list {
    @media ${windowMaxWidth.mobile} {
      padding: 5px;
      height: 30px;

      .ant-btn-icon {
        height: 20px;
      }

      span:not(.ant-btn-icon) {
        display: none;
      }
    }
  }

  &.button-inbox {
    @media ${windowMaxWidth.mobile} {
      height: 30px;
      width: 30px;

      .ant-btn-icon {
        height: 20px;
      }
    }
  }

  &.btn-notification-success {
    border: 1px solid var(--greyscale-white);
    color: var(--greyscale-white);
    font-size: var(--font-size-regular);
    line-height: var(--font-line-height-regular);
    padding: 5px 20px 7px 20px;

    &:hover {
      background: var(--greyscale-white) !important;
      color: var(--primary-oqio-green) !important;
    }
  }
`;

/**
 * `Button` renders a button with styles and behaviors applied based on the provided props.
 * It integrates with Ant Design's Button component and applies additional styling with styled-components.
 * Depending on the `type`, `icon`, `children`, and other props, it adapts its appearance and functionality.
 *
 * @param {object} props - The properties passed to the button component.
 * @param {string} props.className - CSS class for additional styling.
 * @param {ReactNode} props.children - Content inside the button, usually text or icons.
 * @param {boolean} props.disabled - If true, the button will be disabled.
 * @param {boolean} [props.loading=false] - If true, shows a loading indicator.
 * @param {Function} props.onClick - Callback function when the button is clicked.
 * @param {string} [props.type='primary'] - Defines the button's style type (e.g., primary, secondary).
 * @param {boolean} [props.block=false] - If true, the button will fit to the width of its parent.
 * @param {boolean} props.icon - If true, modifies the padding to better fit an icon.
 * @param {boolean} props.danger - If true, applies a danger style to the button.
 * @param {string} props.label - Text label for the button.
 * @param {boolean} props.hideTextOnMobile - If true, hides the text on mobile devices.
 */
const ButtonComponent = ({
  className = '',
  children = null,
  disabled = false,
  loading = false,
  onClick,
  type = 'primary',
  block = false,
  icon,
  danger = false,
  label = null,
  hideTextOnMobile = false
}) => {
  const childrenToRender = loading ? 'Lade...' : children;

  return (
    <StyledButton
      className={className}
      disabled={disabled}
      onClick={onClick}
      type={type}
      loading={loading}
      block={block}
      icon={icon}
      danger={danger}
      $hideTextOnMobile={hideTextOnMobile}
    >
      {label ? label : childrenToRender}
    </StyledButton>
  );
};

export const Button = ButtonComponent;
