import React from 'react';
import styled from 'styled-components';
import AntList from 'antd/lib/list';
import VirtualList from 'rc-virtual-list';
import { windowMaxWidth } from '../Layout/breakpoints';

const LIST_ITEM_HEIGHT = 50;

const StyledList = styled(AntList)`
  background: none;
  color: var(--greyscale-shade);
  font-family: var(--font-family-regular);
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  line-height: var(--font-line-height-medium);
  white-space: nowrap;

  .ant-list-header {
    height: 65px;
    padding-block: 10px;
  }

  &.scrollable-list .ant-spin-container {
    max-height: calc(100vh - 247px);
    min-height: 400px;
    overflow-y: auto;
  }

  .ant-list-item {
    cursor: pointer;
    padding: 15px 40px;
    height: ${LIST_ITEM_HEIGHT}px;
    line-height: var(--font-line-height-medium);

    &:hover {
      background: var(--secondary-whisper);
      transition: background 0.5s;
    }

    &.list-item-no-hover-effect {
      cursor: auto;
      padding: 10px 30px;

      &:hover {
        background: var(--greyscale-white) !important;
        transition: background 0.5s;
      }
    }

    .ant-list-item-action {
      display: flex;
      align-items: center;
      margin-left: 0;

      li {
        display: flex;
        padding: 0;

        &:not(:last-child) {
          margin-right: 20px;
        }

        @media ${windowMaxWidth.splitScreen} {
          &:not(:last-child) {
            margin-right: 10px;
          }
        }

        button {
          padding: 0;

          .ant-btn-icon {
            margin-inline-end: 6px !important;
          }

          span:not(.ant-btn-icon) {
            display: flex;
          }

          &.attachments-modal-list-item {
            flex: unset;
          }

          @media ${windowMaxWidth.splitScreen} {
            .ant-btn-icon {
              margin-inline-end: 0 !important;
            }

            span:not(.ant-btn-icon) {
              display: none;
            }
          }
        }
      }

      .ant-list-item-action-split {
        display: none;
      }
    }

    &:hover {
      background: var(--greyscale-porcelain) !important;
    }

    &.not-selectable {
      cursor: default;
      padding: 10px 30px;

      &:hover {
        background: var(--greyscale-white) !important;
      }
    }

    @media ${windowMaxWidth.mobile} {
      height: auto;
    }
  }

  .ant-list-empty-text {
    padding: 10px;
    margin: 0;
    font-size: var(--font-size-regular);
    line-height: var(--font-line-height-regular);
    color: var(--greyscale-concrete);
    font-weight: var(--font-weight-regular);
  }

  .rc-virtual-list-holder {
    max-height: none !important;
  }

  &.list-left-column {
    .ant-list-item {
      color: var(--greyscale-shade);
      font-weight: var(--font-weight-regular);
      height: 60px;
      margin: 0;
      padding: 10px 20px !important;

      &.item-selected {
        background: var(--primary-oqio-blue) !important;
        color: var(--greyscale-white) !important;
        font-weight: var(--font-weight-medium);
      }

      &.item-header {
        background: var(--secondary-whisper);
        color: var(--greyscale-blackish);
        cursor: default;
        font-size: var(--font-size-small);
        font-stretch: normal;
        font-style: normal;
        font-weight: var(--font-weight-semi-bold);
        height: 40px;
        letter-spacing: var(--font-letter-spacing-regular);
        line-height: var(--font-line-height-medium);
        margin: 0;
        padding: 10px 20px !important;
        text-transform: uppercase;

        &:hover {
          background: var(--secondary-whisper) !important;
        }
      }

      &:hover {
        color: var(--greyscale-blackish);
      }
    }
  }

  &.claims-list {
    .ant-list-item {
      padding: 20px 40px !important;
      align-items: flex-start !important;
      gap: 10px;
      min-height: 90px;
      height: auto;
      position: relative;

      span {
        display: flex;
      }

      &:not(:last-child) {
        border-bottom: 1px solid var(--greyscale-dark-white);
      }

      &.last-visited-item {
        background-color: var(--secondary-whisper);
      }

      @media ${windowMaxWidth.mobile} {
        padding: 15px 10px !important;
        margin: 0 10px;
        justify-content: flex-start;

        column-gap: 10px;
        row-gap: 5px;
      }
    }

    @media ${windowMaxWidth.mobile} {
      margin: 0;
    }
  }

  &.policies-list {
    .ant-list-item {
      padding: 20px 40px !important;
      align-items: flex-start !important;
      gap: 20px;
      height: auto;
      position: relative;
      min-height: 81px;

      span {
        display: flex;
      }

      &:not(:last-child) {
        border-bottom: 1px solid var(--greyscale-dark-white);
      }

      &.last-visited-item {
        background-color: var(--secondary-whisper);
      }

      @media ${windowMaxWidth.mobile} {
        padding: 15px 10px !important;
        margin: 0 10px;
        min-height: auto;
      }
    }

    @media ${windowMaxWidth.mobile} {
      margin: 0;
    }
  }

  .ant-list-empty-text {
    margin-top: 10px;
  }

  &.linked-items-list {
    .ant-list-item {
      @media ${windowMaxWidth.mobile} {
        padding: 10px 20px;
      }
    }
  }
`;

// For the virtual list to work, the virtualListHeight prop must be passed
const List = ({ virtual, virtualListHeight, dataSource, renderItem, ...otherProps }) => {
  if (virtual) {
    return (
      <StyledList {...otherProps}>
        {dataSource.length !== 0 && (
          <VirtualList data={dataSource} height={virtualListHeight} itemHeight={LIST_ITEM_HEIGHT}>
            {(item) => renderItem(item)}
          </VirtualList>
        )}
      </StyledList>
    );
  }
  return <StyledList dataSource={dataSource} renderItem={renderItem} {...otherProps} />;
};

List.Item = StyledList.Item;

export default List;
