import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { ImportHeader } from '../../components/Import/ImportHeader';
import { ImportUploaderSection } from '../../components/Import/ImportUploaderSection';
import { ImportTableSection } from '../../components/Import/ImportTableSection';
import { MainLayout, Tooltip } from '../../components/ui';
import {
  ImportMainWrapper,
  ImportTableStatusTag,
  ImportTableStatusCircle,
  ImportTableStatusLabel,
  ImportTableStatusTooltipMessageList,
  ImportTableStatusTooltipMessage
} from '../../components/ui/Layout/ImportLayout';
import { ImportLoadingState } from '../../components/Import/ImportLoadingState';
import importActions from '../../redux/import/actions';
import { IMPORT_TABLE_ROW_STATUSES } from '../../consts/consts';

const { Header, ContentWrapper } = MainLayout;

/**
 * This component is the main container for the import process.
 * @param {boolean} executeImportLoading
 * @param {boolean} checkImportFileError - this boolean shows if an error occured during the file check API call
 * @param {boolean} executeImportError - this boolean shows if an error occured during the import execution API call
 * @param {object} importJsonData - this object contains the import data in Json format extracted by the backend from the excel file
 * @param {function} clearImportFile
 */
const ImportContainer = ({
  executeImportLoading,
  checkImportFileError,
  executeImportError,
  importJsonData,
  clearImportFile
}) => {
  const navigate = useNavigate();

  const [step, setStep] = useState(1);
  const [importTableColumns, setImportTableColumns] = useState([]);
  const [importTableData, setImportTableData] = useState([]);

  useEffect(() => {
    // When an error occurs during the file check we jump back to the beginning of the import process
    if (checkImportFileError) {
      setStep(1);
      clearImportFile();
    }
  }, [checkImportFileError]);

  useEffect(() => {
    // When an error occurs during the execution of the import we jump back to the beginning of the import process
    if (executeImportError) {
      setStep(1);
      clearImportFile();
    }
  }, [executeImportError]);

  useEffect(() => {
    // When the import execution is loading we increment the step in order to show the loading state
    if (executeImportLoading) {
      handleIncrementStep();
    }
  }, [executeImportLoading]);

  // Parse the import data for the Table component
  useEffect(() => {
    if (importJsonData) {
      parseImportData(importJsonData);
    } else {
      setImportTableColumns([]);
      setImportTableData([]);
    }
  }, [importJsonData]);

  const renderStatusTooltip = (errorMessage) => {
    return (
      <ImportTableStatusTooltipMessageList>
        {errorMessage.map((message) => (
          <ImportTableStatusTooltipMessage key={message}>· {message}</ImportTableStatusTooltipMessage>
        ))}
      </ImportTableStatusTooltipMessageList>
    );
  };

  const renderStatusCell = (status, errorMessage) => {
    if (status === IMPORT_TABLE_ROW_STATUSES.invalid) {
      return (
        <Tooltip placement="topLeft" title={renderStatusTooltip(errorMessage)}>
          <ImportTableStatusTag $color={status.color}>
            <ImportTableStatusCircle $color={status.color} />
            <ImportTableStatusLabel>{status.label}</ImportTableStatusLabel>
          </ImportTableStatusTag>
        </Tooltip>
      );
    }
    return (
      <ImportTableStatusTag $color={status.color}>
        <ImportTableStatusCircle $color={status.color} />
        <ImportTableStatusLabel>{status.label}</ImportTableStatusLabel>
      </ImportTableStatusTag>
    );
  };

  const getDataRowStatus = (row) => {
    const { valid_row, new_policy, new_building, new_building_to_policy, updated_building, updated_policy } = row;
    if (!valid_row) {
      return IMPORT_TABLE_ROW_STATUSES.invalid;
    }

    // Update has prioroty over new for now. This is why the update is checked first.
    // If a row has both a new and an updated entity, it will be marked as updated.
    if (updated_building || updated_policy) {
      return IMPORT_TABLE_ROW_STATUSES.updated;
    }

    if (new_policy || new_building || new_building_to_policy) {
      return IMPORT_TABLE_ROW_STATUSES.new;
    }

    return IMPORT_TABLE_ROW_STATUSES.no_changes;
  };

  const parseImportData = (rawData) => {
    const columnKeysArray = Object.keys(rawData.header_row);
    const columns = columnKeysArray.map((key) => {
      const columnClassName = rawData.header_row[key] ? 'valid-column' : 'invalid-column';
      return {
        key: key,
        className: columnClassName,
        title: key,
        align: 'left',
        dataIndex: key,
        ellipsis: { showTitle: true },
        width: 200
      };
    });
    columns.unshift({
      key: 'STATUS',
      title: 'STATUS',
      align: 'center',
      fixed: true,
      className: 'status-column',
      dataIndex: 'status',
      width: 120,
      render: (status, rowData) => renderStatusCell(status, rowData.error_message)
    });
    setImportTableColumns(columns);

    const parsedDataRows = rawData.data_rows.map((row) => {
      return {
        status: getDataRowStatus(row),
        ...row
      };
    });
    setImportTableData(parsedDataRows);
  };

  const handleIncrementStep = () => {
    setStep(step + 1);
  };

  const handleDecrementStep = () => {
    setStep(step - 1);
  };

  const handleLeaveImport = () => {
    clearImportFile();
    navigate('/');
  };

  // The actions buttons should not be shown during the loading states or in the first step of the import process.
  const showActionButtons = step === 2 && importTableColumns.length !== 0;

  const renderImportMainContent = () => {
    // This is the loading state shown while the excel file is being processed
    if (importTableColumns.length === 0 && step === 2) {
      return <ImportLoadingState isLoading={true} isFileCheckLoadingState={true} />;
    }

    switch (step) {
      case 1:
        return <ImportUploaderSection onFileUpload={handleIncrementStep} />;
      case 2:
        return <ImportTableSection importTableColumns={importTableColumns} importTableData={importTableData} />;
      case 3:
        // This is the loading state shown while the data is being imported
        return <ImportLoadingState isLoading={executeImportLoading} importSummary={importJsonData.meta_data} />;
      default:
        return <ImportUploaderSection onFileUpload={handleIncrementStep} />;
    }
  };

  return (
    <ImportMainWrapper>
      <Header className="import-inner-wrapper">
        <ImportHeader
          showActionButtons={showActionButtons}
          onCancelButton={handleDecrementStep}
          onNavigateBackButton={handleLeaveImport}
        />
      </Header>
      <ContentWrapper className="import-inner-wrapper">{renderImportMainContent()}</ContentWrapper>
    </ImportMainWrapper>
  );
};

const { clearImportFile } = importActions;

const mapDispatchToProps = {
  clearImportFile
};

const mapStateToProps = (state) => {
  return {
    executeImportLoading: state.Import.get('executeImportLoading'),
    checkImportFileError: state.Import.get('checkImportFileError'),
    executeImportError: state.Import.get('executeImportError'),
    importJsonData: state.Import.get('importJsonData')
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ImportContainer);
