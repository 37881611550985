import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import {
  onChangeUserPassword,
  onCreateUser,
  onGetUserRoles,
  onLoadAll,
  onUpdateUser,
  onDeleteUser,
  onConfirmOptIn,
  onLoadUserIntercomHmac
} from './apiCalls';
import actions from './actions';
import authActions from '../auth/actions';
import renderNotification from '../../helpers/notifications/renderNotification';
import { NOTIFICATION_MESSAGES } from '../../localization/Notifications/dictionary';

export function* loadUsers({ params }) {
  try {
    const response = yield call(onLoadAll, params);
    if (response.data) {
      yield put(actions.getUsersSuccess(response.data));
    } else {
      yield put(actions.getUsersError());
    }
  } catch (error) {
    yield put(actions.getUsersError());
  }
}

export function* loadUsersByOrganizationSaga({ organizationId }) {
  try {
    const response = yield call(onLoadAll, { organization_id: organizationId });
    if (response.data) {
      yield put(actions.getUsersByOrganizationSuccess(response.data));
    } else {
      yield put(actions.getUsersByOrganizationError());
    }
  } catch (error) {
    yield put(actions.getUsersByOrganizationError());
  }
}

export function* createUser({ payload, callback }) {
  try {
    const response = yield call(onCreateUser, payload.user);
    if (response.status === 200) {
      const { user_create_success } = NOTIFICATION_MESSAGES;
      renderNotification({ type: 'success', message: user_create_success });
      yield put(actions.createUserSuccess());
      if (callback && typeof callback === 'function') {
        callback();
      }
    } else {
      yield put(actions.createUserError());
    }
  } catch (error) {
    yield put(actions.createUserError());
  }
}

export function* updateUser({ userId, params, callback }) {
  try {
    const response = yield call(onUpdateUser, userId, params);
    if (response.data) {
      const { user_update_success } = NOTIFICATION_MESSAGES;
      renderNotification({ type: 'success', message: user_update_success });
      yield put(actions.updateUserSuccess());
      if (callback && typeof callback === 'function') {
        callback();
      }
    } else {
      yield put(actions.updateUserError());
    }
  } catch (error) {
    yield put(actions.updateUserError());
  }
}

export function* changeUserPassword({ currentPassword, newPassword, userId }) {
  try {
    const response = yield call(onChangeUserPassword, currentPassword, newPassword, userId);
    if (response.status === 200) {
      yield put(actions.changeUserPasswordSuccess());
    } else {
      yield put(actions.changeUserPasswordError());
    }
  } catch (error) {
    yield put(actions.changeUserPasswordError());
  }
}

export function* deleteUser({ userId }) {
  try {
    const response = yield call(onDeleteUser, userId);
    if (response.status === 200) {
      const { user_delete_success } = NOTIFICATION_MESSAGES;
      renderNotification({ type: 'success', message: user_delete_success });
      yield put(actions.deleteUserSuccess());
    } else {
      yield put(actions.deleteUserError());
    }
  } catch (error) {
    yield put(actions.deleteUserError());
  }
}

export function* getUserRoles({ organisationType }) {
  try {
    const response = yield call(onGetUserRoles, organisationType);
    if (response.data) {
      yield put(actions.getUserRolesSuccess(response.data));
    } else {
      yield put(actions.getUserRolesError());
    }
  } catch (error) {
    yield put(actions.getUserRolesError());
  }
}

export function* confirmOptIn({ userId }) {
  try {
    const response = yield call(onConfirmOptIn, userId);
    if (response.status === 200) {
      yield put(actions.confirmOptInSuccess());

      // We call validateUser to get the right user data where the terms of use are approved
      yield put(authActions.validateUser());
    } else {
      yield put(actions.confirmOptInError());
    }
  } catch (error) {
    yield put(actions.confirmOptInError());
  }
}

export function* loadUserIntercomHmac({ userId }) {
  try {
    const response = yield call(onLoadUserIntercomHmac, userId);
    if (response.status === 200) {
      yield put(actions.loadUserIntercomHmacSuccess());
    } else {
      yield put(actions.loadUserIntercomHmacError());
    }
  } catch (error) {
    yield put(actions.loadUserIntercomHmacError());
  }
}

const getSelectedOrganization = (state) => state.Organizations.get('selectedOrganization');

export function* reloadUsersByOrganizationSaga() {
  const selectedOrganization = yield select(getSelectedOrganization);
  if (selectedOrganization) {
    yield put(actions.getUsersByOrganization(selectedOrganization?.id));
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_USERS, loadUsers),
    takeEvery(actions.LOAD_USERS_BY_ORGANIZATION, loadUsersByOrganizationSaga),
    takeEvery(actions.CREATE_USER, createUser),
    takeEvery(actions.CREATE_USER_SUCCESS, reloadUsersByOrganizationSaga),
    takeEvery(actions.UPDATE_USER, updateUser),
    takeEvery(actions.UPDATE_USER_SUCCESS, reloadUsersByOrganizationSaga),
    takeEvery(actions.CHANGE_USER_PASSWORD, changeUserPassword),
    takeEvery(actions.DELETE_USER, deleteUser),
    takeEvery(actions.DELETE_USER_SUCCESS, reloadUsersByOrganizationSaga),
    takeEvery(actions.GET_USER_ROLES, getUserRoles),
    takeEvery(actions.CONFIRM_OPT_IN, confirmOptIn),
    takeEvery(actions.LOAD_USER_INTERCOM_HMAC, loadUserIntercomHmac)
  ]);
}
