import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import qs from 'query-string';
import { sentryCaptureValidationException } from '../../config/sentry';
import { Button, Form, InputPassword, Modal } from '../../components/ui';
import authAction from '../../redux/auth/actions';
import { ResetPasswordStyleWrapper, ResetPasswordTextWrapper } from './ResetPassword.style';
import PasswordRules from './PasswordRules';
import { DEFAULT_RULES } from '../../consts/formRules';

/**
 * Functional component for the reset password process.
 * Allows users to set a new password using a token from the query string.
 * @property {Function} resetPassword - function to trigger the reset password process.
 * @property {Boolean} resetPasswordError - boolean indicating if there was an error during reset password.
 * @property {Boolean} resetPasswordLoading - boolean indicating if the reset password process is loading.
 */
const ResetPassword = ({ resetPassword, resetPasswordError, resetPasswordLoading }) => {
  const [form] = Form.useForm();
  const { resetFields, validateFields } = form;

  const location = useLocation(); // Use useLocation to get the location object
  const navigate = useNavigate(); // Use useNavigate to get the navigate function
  const token = qs.parse(location.search, { ignoreQueryPrefix: true }).token || null;

  // Reset form fields when the component unmounts
  useEffect(() => {
    return () => {
      resetFields();
    };
  }, []);

  // Handle form submission and trigger reset password process
  const handleSubmit = () =>
    validateFields()
      .then((values) => {
        if (resetPasswordLoading) {
          return;
        }
        const { password } = values;
        resetPassword(token, password, navigate);
      })
      .catch((error) => {
        sentryCaptureValidationException(error);
      });

  return (
    <Modal
      className="modal-no-padding"
      title="Neues Passwort"
      style={{ top: 140 }}
      open
      closable={false}
      mask={false}
      getContainer="no-pointer-events"
      createContainer
      footer={null}
    >
      <ResetPasswordStyleWrapper>
        <ResetPasswordTextWrapper>Bitte vergeben Sie ein neues Passwort.</ResetPasswordTextWrapper>
        <Form form={form}>
          <Form.Item
            className="reset-password-input"
            label="Neues Passwort"
            name="password"
            rules={DEFAULT_RULES.INPUT_REQUIRED}
          >
            <InputPassword autoComplete="current-password" $hasErrors={resetPasswordError} />
          </Form.Item>
          <PasswordRules />
          <Form.Item>
            <div className="reset-password-button-wrapper">
              <Button type="primary" onClick={handleSubmit}>
                Speichern
              </Button>
            </div>
          </Form.Item>
        </Form>
      </ResetPasswordStyleWrapper>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  isLoggedIn: state.Auth.get('isLoggedIn'),
  resetPasswordError: state.Auth.get('resetPasswordError'),
  resetPasswordLoading: state.Auth.get('resetPasswordLoading')
});

const { resetPassword } = authAction;

export default connect(mapStateToProps, { resetPassword })(ResetPassword);
