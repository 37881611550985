import React from 'react';
import { LoadingIndicator } from '../ui';
import {
  ImportLoadingStateWrapper,
  ImportSummaryWrapper,
  ImportSummaryRow,
  ImportSummaryIcon,
  ImportSummaryHeader,
  ImportSummaryText,
  ImportSummaryHighlightedText
} from '../ui/Layout/ImportLayout';

import { IconCheck } from '../ui/Icons';

/**
 * This component is used for displaing the loading states of the import process.
 * @param {boolean} isLoading - shows if the loading or the success state should be displayed
 * @param {boolean} isFileCheckLoadingState - flag to show if the first step of the import is loading or the second one.
 * @param {array} importSummary - this array contains the import summary entries, they have the structure of metadata objects.
 */
export const ImportLoadingState = ({ isLoading, isFileCheckLoadingState = false, importSummary }) => {
  const loadingMessage = isFileCheckLoadingState ? 'Die Datei wird eingelesen.' : 'Die Daten werden importiert.';

  const renderImportSummaryRow = () => {
    return importSummary.map((summaryEntry) => {
      let entityName = '';
      let operation = '';
      switch (summaryEntry.rel) {
        case 'new_building_count': {
          entityName = 'Gebäude';
          operation = 'erstellt';
          break;
        }
        case 'new_policy_count': {
          if (summaryEntry.count === 1) {
            entityName = 'Vertrag';
          } else {
            entityName = 'Verträge';
          }
          operation = 'erstellt';
          break;
        }
        case 'new_building_to_policy_count': {
          if (summaryEntry.count === 1) {
            entityName = 'Verknüpfung';
          } else {
            entityName = 'Verknüpfungen';
          }
          operation = 'erstellt';
          break;
        }
        case 'updated_building_count': {
          entityName = 'Gebäude';
          operation = 'aktualisiert';
          break;
        }
        case 'updated_policy_count': {
          if (summaryEntry.count === 1) {
            entityName = 'Vertrag';
          } else {
            entityName = 'Verträge';
          }
          operation = 'aktualisiert';
          break;
        }
        default:
          return React.Fragment;
      }

      if (!summaryEntry.count) {
        return React.Fragment;
      }

      return (
        <ImportSummaryRow key={summaryEntry.rel}>
          <ImportSummaryIcon>
            <IconCheck />
          </ImportSummaryIcon>
          <ImportSummaryHighlightedText>{summaryEntry.count}</ImportSummaryHighlightedText>
          <ImportSummaryText>
            {entityName} {operation}
          </ImportSummaryText>
        </ImportSummaryRow>
      );
    });
  };

  const getSuccessMessage = () => {
    if (!isFileCheckLoadingState) {
      return (
        <ImportSummaryWrapper>
          <ImportSummaryHeader>Import erfolgreich</ImportSummaryHeader>
          {renderImportSummaryRow()}
        </ImportSummaryWrapper>
      );
    }
    return React.Fragment;
  };

  return (
    <ImportLoadingStateWrapper>
      <LoadingIndicator
        isLoading={isLoading}
        loadingStateMessage={loadingMessage}
        successMessage={getSuccessMessage()}
      />
    </ImportLoadingStateWrapper>
  );
};
