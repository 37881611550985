import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import {
  InitialSigninWrapper,
  EmailWrapper,
  EmailLabel,
  GreetingMessageWrapper,
  UserAgreementMessageWrapper,
  SubmitButtonWrapper
} from './InitialSignin.style';
import { Button, Checkbox, Form, InputPassword, Modal } from '../../components/ui';
import authAction from '../../redux/auth/actions';
import PasswordRules from './PasswordRules';
import { sentryCaptureValidationException } from '../../config/sentry';
import { DEFAULT_RULES } from '../../consts/formRules';

/**
 * Functional component for the initial sign-in process.
 * Allows users to set their password and accept user agreements.
 * @property {Object} config - the application configuration data.
 * @property {Boolean} signInError - boolean indicating if there was an error during sign-in.
 * @property {Boolean} signInLoading - boolean indicating if the sign-in process is loading.
 * @property {Function} signIn - function to trigger the sign-in process.
 * @property {Object} user - the current logged-in user information.
 * @property {Function} logout - function to log out the user.
 */
const InitialSignIn = ({ config, signInError, signInLoading, signIn, user, logout }) => {
  const [form] = Form.useForm();
  const { validateFields } = form;
  const location = useLocation();
  const navigate = useNavigate();
  const email = queryString.parse(location.search).email || null;
  const token = queryString.parse(location.search).token || null;

  // Logout user if already logged in to prevent any issues with Intercom
  useEffect(() => {
    if (user) {
      logout();
    }
  }, []);

  // Handle form submission and trigger sign-in process
  const handleSubmit = () => {
    validateFields()
      .then((values) => {
        if (signInLoading) {
          return;
        }
        const { password } = values;
        if (password) {
          const payload = {
            token,
            username: email,
            password
          };
          signIn(payload, navigate);
        }
      })
      .catch((error) => {
        sentryCaptureValidationException(error);
      });
  };

  // Render the user agreement message with links to terms and privacy policy
  const renderUserAgreementMessage = (config) => (
    <>
      {'Mit der Anmeldung akzeptieren Sie die '}
      <a
        href={config && config.terms_of_use ? config.terms_of_use.url : '#'}
        target={config && config.terms_of_use ? '_blank' : ''}
        rel="noopener noreferrer"
      >
        Nutzungsbedingungen
      </a>
      {' und die '}
      <a
        href={config && config.privacy_statement ? config.privacy_statement.url : '#'}
        target={config && config.privacy_statement ? '_blank' : ''}
        rel="noopener noreferrer"
      >
        Datenschutzinformationen
      </a>
      {' des Portals. '}
    </>
  );

  return (
    <Modal
      className="modal-no-padding initial-signin-modal"
      title="Willkommen"
      open
      closable={false}
      mask={false}
      footer={null}
      getContainer="no-pointer-events"
      createContainer
    >
      <InitialSigninWrapper>
        <GreetingMessageWrapper>Bitte vergeben Sie ein Passwort für Ihren Zugang.</GreetingMessageWrapper>
        <EmailLabel>E-Mail</EmailLabel>
        <EmailWrapper>{email || '—.—@oqio.de'}</EmailWrapper>
        <Form form={form} requiredMark="optional">
          <Form.Item
            name="password"
            label="Passwort"
            className="initial-signin-password-input"
            rules={DEFAULT_RULES.INPUT_REQUIRED}
          >
            <InputPassword $hasErrors={signInError} />
          </Form.Item>

          <PasswordRules withMargin={false} />
          <UserAgreementMessageWrapper>
            <Form.Item
              name="terms_of_use"
              valuePropName="checked"
              rules={[
                {
                  validator: (_, value) => (value ? Promise.resolve() : Promise.reject(new Error('↑ Pflichtfeld')))
                }
              ]}
            >
              <Checkbox> {renderUserAgreementMessage(config)}</Checkbox>
            </Form.Item>
          </UserAgreementMessageWrapper>
        </Form>
        <SubmitButtonWrapper>
          <Button type="primary" onClick={handleSubmit} block>
            Speichern
          </Button>
        </SubmitButtonWrapper>
      </InitialSigninWrapper>
    </Modal>
  );
};

const mapStateToProps = (state) => ({
  config: state.Config.get('config'),
  signInError: state.Auth.get('signInError'),
  signInLoading: state.Auth.get('signInLoading'),
  user: state.Auth.get('user')
});

const { signIn, logout } = authAction;

export default connect(mapStateToProps, { signIn, logout })(InitialSignIn);
