import React, { useEffect } from 'react';
import { Table } from '../ui';
import { ImportTableWrapper } from '../ui/Layout/ImportLayout';

/**
 * This component is the second step of the import process.
 * It renders the import table contining the processed data from the import file
 * plus a status column that marks if a row is valid or not.
 * @param {Array} importTableColumns
 * @param {Array} importTableData
 */
export const ImportTableSection = ({ importTableColumns, importTableData }) => {
  // This variable is used to determine the height of the table body (the table header is fixed)
  // The 170px are the sum of the page header (100px), the table columns headers (41px), the 20px bottom margin and the 2px border
  const [tableBodyHeight, setTableBodyHeight] = React.useState(window.innerHeight - 163);

  useEffect(() => {
    const handleResize = () => {
      setTableBodyHeight(window.innerHeight - 163);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const getRowClassName = (record) => {
    return record.valid_row ? 'valid-row' : 'invalid-row';
  };

  return (
    <ImportTableWrapper>
      <Table
        virtual
        scroll={{ y: tableBodyHeight }}
        rowHoverable={false}
        pagination={false}
        columns={importTableColumns}
        dataSource={importTableData}
        rowClassName={getRowClassName}
      />
    </ImportTableWrapper>
  );
};
