import styled from 'styled-components';

const NAVIGATION_HEIGHT = 60;
const HEADER_HEIGHT = 101; // 100px + 1px border
const FOOTER_HEIGHT = 80;
const WARNING_HEIGHT = 40;
const RESERVED_HEIGHT = NAVIGATION_HEIGHT + HEADER_HEIGHT + FOOTER_HEIGHT;
const RESERVED_HEIGHT_WITH_WARNING = RESERVED_HEIGHT + WARNING_HEIGHT;

// Styles for the WorklistClaimsMessagesList component
const WorklistClaimMessagesWrapper = styled.div`
  background-color: var(--secondary-whisper);
  display: flex;
  flex-direction: column;
`;

const WorklistClaimMessagesListWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: calc(100vh - ${(props) => (props.$hasWarning ? RESERVED_HEIGHT_WITH_WARNING : RESERVED_HEIGHT)}px);
  overflow: auto;
  padding-bottom: 40px;
  scrollbar-color: rgba(136, 136, 136, 0.3) transparent;
  scrollbar-width: thin;
`;

const WorklistClaimMessagesListOverlay = styled.div`
  background: linear-gradient(180deg, rgba(245, 246, 250, 0%) 0%, var(--secondary-whisper) 100%);
  bottom: 80px;
  display: flex;
  height: 40px;
  justify-content: center;
  pointer-events: none;
  position: fixed;
  width: 100%;
  z-index: 1;
`;

// Styles for the WorklistClaimMessage component

const WorklistClaimMessageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: 0;
  padding-right: 40px;

  &.message-sent {
    flex-direction: row-reverse;
    padding-left: 40px;
    padding-right: 0;
  }

  &:not(.message-system) {
    padding-bottom: 10px;
    padding-top: 10px;
  }
  &.message-system {
    padding-bottom: 5px;
    padding-top: 5px;
  }
  &.worklist-claim-messages-list-wrapper {
    border-bottom: 1px solid #e9eaed;
    padding-top: 20px;
  }
`;

const WorklistClaimMessageContent = styled.div`
  background-color: var(--greyscale-white);
  color: var(--greyscale-blackish);
  flex: auto;
  padding: 10px 15px;

  &:not(.message-system) {
    border: 1px solid var(--greyscale-dark-white);
    border-radius: var(--border-radius-regular);
  }

  &.active:not(.message-system) {
    border-color: var(--primary-oqio-blue);
  }

  &.message-system {
    background-color: unset;
    border-bottom: 1px solid var(--greyscale-light-grey);
    padding: 15px;
  }
`;

const WorklistClaimMessageHeader = styled.div`
  display: flex;
  flex-wrap: no-wrap;
  font-size: var(--font-size-small);
  justify-content: space-between;
  line-height: var(--font-line-height-small);
`;

const WorklistClaimMessageSubHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  font-size: var(--font-size-small);
  justify-content: space-between;
  line-height: var(--font-line-height-small);
  margin-bottom: 10px;
`;

const WorklistClaimMessageTitle = styled.div`
  display: flex;
  font-weight: var(--font-weight-semi-bold);

  &.active {
    color: var(--primary-oqio-blue);
  }
`;

const WorklistClaimMessageRecipients = styled.div`
  color: var(--greyscale-shade);
  display: flex;
  word-break: break-all;
`;

const WorklistClaimMessageCreator = styled.div`
  color: var(--greyscale-blackish);
  display: flex;
  flex-wrap: wrap;

  &.active {
    color: var(--primary-oqio-blue);
  }
`;

const WorklistClaimMessageCreatorOrganization = styled.div`
  display: flex;
  font-weight: var(--font-weight-semi-bold);
  margin-right: 5px;
  word-break: break-all;
`;

const WorklistClaimMessageCreatorUser = styled.div`
  display: flex;
  word-break: break-all;
`;

const WorklistClaimMessageCreatorSystem = styled.div`
  color: var(--greyscale-shade);
  display: flex;
  word-break: break-all;
`;

const WorklistClaimMessageHeaderDate = styled.div`
  align-items: flex-end;
  display: flex;
  flex: 1 0 150px;
  flex-direction: column;

  &.active {
    color: var(--primary-oqio-blue);
    font-weight: var(--font-weight-semi-bold);
  }
`;

const WorklistClaimMessageDescription = styled.p`
  font-size: var(--font-size-medium);
  line-height: var(--font-line-height-regular);
  margin: 0;
  white-space: break-spaces;
  word-break: break-word;
`;

const WorklistClaimMessageSystemDescription = styled.p`
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-small);
  margin: 0;
`;

const WorklistClaimMessageDescriptionList = styled.ul`
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-small);
  margin: 0;
  padding-left: 15px;
`;

const WorklistClaimMessageDescriptionListItem = styled.li`
  list-style: initial;
  white-space: pre-wrap;
  word-break: break-word;
`;

// Styles for the WorklistClaimMessage reminder

const WorklistClaimMessageReminderWrapper = styled.div`
  border-left: 2px solid var(--primary-oqio-blue);
  color: var(--greyscale-blackish);
  display: flex;
  flex-direction: column;
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-small);
  margin-bottom: 10px;
  margin-left: -15px;
  padding-left: 15px;
`;

const WorklistClaimMessageReminderDate = styled.div`
  color: var(--primary-oqio-blue);
  display: flex;
  flex-direction: column;
  font-weight: var(--font-weight-semi-bold);
`;

const WorklistClaimMessageReminderInfo = styled.div`
  display: flex;
  flex-direction: column;
  word-break: break-word;
`;

// Styles for the WorklistClaimMessage attachments

const WorklistClaimMessageAttachmentsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 12px;
`;

const WorklistClaimMessageFile = styled.div`
  display: block;
  height: 24px;
`;

const WorklistClaimMessageAttachmentName = styled.div`
  align-items: center;
  color: var(--greyscale-shade);
  display: flex;
  flex-direction: row;
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-small);

  &:hover {
    color: var(--primary-oqio-blue);
    svg {
      path {
        stroke: var(--primary-oqio-blue);
      }
    }
  }
`;

// Styles for the WorklistClaimMessage action buttons

const WorklistClaimMessageActionsButtons = styled.div`
  align-items: center;
  display: flex;
  flex: 0 0 40px;
  flex-direction: column;
  padding: 15px 5px;
`;

const WorklistClaimMessageActionsAvatar = styled.div`
  background-color: var(--greyscale-blackish);
  border-radius: var(--border-radius-circle);
  display: flex;
  height: 30px;
  width: 30px;
`;

const ActiveToggleButton = styled.button`
  align-items: center;
  background-color: unset;
  border: none;
  display: flex;
  height: 20px;
  justify-content: center;
  margin-bottom: 3px;
  padding: 0;
  position: relative;
  transition: all 0.2s;
  width: 20px;

  svg {
    height: 16px;
    width: 16px;
    circle {
      stroke: var(--greyscale-concrete);
    }
  }

  &:hover {
    cursor: pointer;

    svg {
      circle {
        stroke: var(--primary-oqio-blue-hover);
      }
    }
  }

  &.active {
    svg {
      fill: var(--primary-oqio-blue);
      circle {
        stroke: var(--primary-oqio-blue);
      }
    }
    &:hover {
      svg {
        fill: var(--primary-oqio-blue-hover);
        circle {
          stroke: var(--primary-oqio-blue-hover);
        }
      }
      transition: all 0.1s;
    }
  }
`;

const ReminderButton = styled.button`
  align-items: center;
  background-color: unset;
  border: none;
  display: flex;
  height: 20px;
  justify-content: center;
  padding: 0;
  position: relative;
  transition: all 0.2s;
  width: 20px;

  svg {
    height: 18px;
    width: 18px;
  }

  &.active {
    svg {
      path {
        fill: var(--primary-oqio-blue);
      }
    }

    &:hover {
      cursor: pointer;

      svg {
        path {
          fill: var(--primary-oqio-blue-hover);
        }
      }
    }
  }

  &:not(.active) {
    svg {
      path {
        stroke: var(--greyscale-concrete);
      }
    }

    &:hover {
      cursor: pointer;

      svg {
        path {
          stroke: var(--primary-oqio-blue-hover);
        }
      }
    }
  }
`;

const ReminderButtonActive = styled.button`
  align-items: center;
  background-color: unset;
  border: none;
  display: flex;
  height: 20px;
  justify-content: center;
  padding: 0;
  width: 20px;

  div {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
    width: 100%;
  }

  svg {
    path {
      fill: var(--primary-oqio-blue);
    }
  }
`;

// Styles for the WorklistClaimMessageFooter component

const WorklistClaimMessagesFooterWrapper = styled.div`
  background-color: var(--greyscale-white);
  display: flex;
  height: 80px;
  justify-content: space-between;
  padding: 20px;
`;

const WorklistClaimMessagesFooterFilterWrapper = styled.div`
  align-items: center;
  color: var(--primary-oqio-blue);
  display: flex;
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
  line-height: var(--font-line-height-regular);
`;

const WorklistClaimMessagesFooterFilterIcon = styled.div`
  display: flex;
  height: 20px;
  margin-right: 7px;
  width: 20px;
  &:not(.active) {
    svg {
      path {
        stroke: var(--primary-oqio-blue);
      }
    }
  }

  &.active {
    svg {
      path {
        fill: var(--primary-oqio-blue);
      }
    }
  }
`;

const WorklistClaimMessagesFooterFilterLabel = styled.div`
  display: flex;
`;

export const WorklistClaimMessagesLayout = {
  WorklistClaimMessagesWrapper,
  WorklistClaimMessagesListWrapper,
  WorklistClaimMessagesListOverlay,
  WorklistClaimMessageWrapper,
  WorklistClaimMessageContent,
  WorklistClaimMessageHeader,
  WorklistClaimMessageSubHeader,
  WorklistClaimMessageTitle,
  WorklistClaimMessageRecipients,
  WorklistClaimMessageCreator,
  WorklistClaimMessageCreatorOrganization,
  WorklistClaimMessageCreatorUser,
  WorklistClaimMessageCreatorSystem,
  WorklistClaimMessageHeaderDate,
  WorklistClaimMessageDescription,
  WorklistClaimMessageSystemDescription,
  WorklistClaimMessageDescriptionList,
  WorklistClaimMessageDescriptionListItem,
  WorklistClaimMessageReminderWrapper,
  WorklistClaimMessageReminderDate,
  WorklistClaimMessageReminderInfo,
  WorklistClaimMessageAttachmentsWrapper,
  WorklistClaimMessageFile,
  WorklistClaimMessageAttachmentName,
  WorklistClaimMessageActionsButtons,
  WorklistClaimMessageActionsAvatar,
  ActiveToggleButton,
  ReminderButton,
  ReminderButtonActive,
  WorklistClaimMessagesFooterWrapper,
  WorklistClaimMessagesFooterFilterWrapper,
  WorklistClaimMessagesFooterFilterIcon,
  WorklistClaimMessagesFooterFilterLabel
};
