import React from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import TopbarUserMenu from '../TopbarUserMenu/TopbarUserMenu';
import Logo from '../Logo/Logo';
import { HeaderMenu } from '../HeaderMenu/HeaderMenu';
import HeaderMenuDrawer from '../HeaderMenu/HeaderMenuDrawer';
import { windowMaxWidth } from '../ui/Layout/breakpoints';

const HeaderWrapper = styled.div`
  background-color: var(--greyscale-white);
  box-shadow: var(--box-shadow-header-menu);
  display: flex;
  height: 60px;
  padding: 0 30px;
  width: 100vw;
  z-index: 999;

  @media (${windowMaxWidth.mobile}) {
    padding: 0 20px;
  }
`;

const HederInnerWrapper = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  width: calc(100vw - 40px);
`;

const Header = ({ config, userRights }) => {
  const location = useLocation();
  return (
    <HeaderWrapper>
      <HederInnerWrapper>
        <HeaderMenuDrawer userRights={userRights} path={location.pathname} />
        <Logo
          agencyLogo={config && config.tenant_logo && config.tenant_logo.url}
          agencyName={config && config.tenant_name}
        />
        <HeaderMenu userRights={userRights} path={location.pathname} />
        <TopbarUserMenu />
      </HederInnerWrapper>
    </HeaderWrapper>
  );
};

const mapStateToProps = (state) => ({
  config: state.Config.get('config'),
  configLoading: state.Config.get('configLoading'),
  userRights: state.Auth.get('userRights')
});

export default connect(mapStateToProps)(Header);
