import { Map } from 'immutable';
import actions from './actions';

const initState = new Map({
  worklistClaims: [],
  worklistClaimsLoading: false,
  worklistClaimsError: false,
  worklistClaimMessages: null,
  worklistClaimMessagesLoading: false,
  worklistClaimMessagesError: false,
  createWorklistClaimMessageLoading: false,
  createWorklistClaimMessageError: false,
  updateWorklistClaimMessageLoading: false,
  updateWorklistClaimMessageError: false,
  messageRecipientOrganizations: [],
  messageRecipientOrganizationsLoading: false,
  messageRecipientOrganizationsError: false,
  claimActiveMessagesCount: 0,
  claimActiveMessagesCountLoading: false,
  claimActiveMessagesCountError: false,
  claimsListActiveMessagesCount: 0,
  claimsListActiveMessagesCountLoading: false,
  claimsListActiveMessagesCountError: false,
  worklistHeaderActiveMessagesCount: 0,
  worklistClaimMessagesFilter: '',
  worklistClaimMessagesDefaultFilter: '',
  worklistClaimMessagesFilterIsChanged: false,
  isCreateMessageTouched: false,
  newMessageAttachments: [],
  isNewMessageAttachmentsUploadLoading: false
});

const reducer = (state = initState, action) => {
  switch (action.type) {
    case actions.GET_WORKLIST_CLAIMS:
      return state.set('worklistClaimsLoading', true);
    case actions.GET_WORKLIST_CLAIMS_SUCCESS:
      return state.set('worklistClaims', action.claims).set('worklistClaimsLoading', false);
    case actions.GET_WORKLIST_CLAIMS_ERROR:
      return state.set('worklistClaimsError', true).set('worklistClaimsLoading', false);
    case actions.GET_WORKLIST_CLAIM_MESSAGES:
      return state.set('worklistClaimMessagesLoading', true).set('worklistHeaderActiveMessagesCount', null);
    case actions.GET_WORKLIST_CLAIM_MESSAGES_SUCCESS:
      return state.set('worklistClaimMessages', action.messages).set('worklistClaimMessagesLoading', false);
    case actions.GET_WORKLIST_CLAIM_MESSAGES_ERROR:
      return state.set('worklistClaimMessagesError', true).set('worklistClaimMessagesLoading', false);
    case actions.GET_MESSAGE_RECIPIENT_ORGANIZATIONS:
      return state.set('messageRecipientOrganizationsLoading', true).set('messageRecipientOrganizationsError', false);
    case actions.GET_MESSAGE_RECIPIENT_ORGANIZATIONS_SUCCESS:
      return state
        .set('messageRecipientOrganizations', action.organizations)
        .set('messageRecipientOrganizationsLoading', false)
        .set('messageRecipientOrganizationsError', false);
    case actions.GET_MESSAGE_RECIPIENT_ORGANIZATIONS_ERROR:
      return state.set('messageRecipientOrganizationsLoading', false).set('messageRecipientOrganizationsError', true);
    case actions.GET_CLAIM_ACTIVE_MESSAGES_COUNT:
      return state.set('claimActiveMessagesCountLoading', true);
    case actions.GET_CLAIM_ACTIVE_MESSAGES_COUNT_SUCCESS:
      return state
        .set('claimActiveMessagesCount', action.count)
        .set('claimActiveMessagesCountLoading', false)
        .set('claimActiveMessagesCountError', false);
    case actions.GET_CLAIM_ACTIVE_MESSAGES_COUNT_ERROR:
      return state.set('claimActiveMessagesCountError', true).set('claimActiveMessagesCountLoading', false);
    case actions.GET_CLAIMS_LIST_ACTIVE_MESSAGES_COUNT:
      return state.set('claimsListActiveMessagesCountLoading', true);
    case actions.GET_CLAIMS_LIST_ACTIVE_MESSAGES_COUNT_SUCCESS:
      return state
        .set('claimsListActiveMessagesCount', action.count)
        .set('claimsListActiveMessagesCountLoading', false);
    case actions.GET_CLAIMS_LIST_ACTIVE_MESSAGES_COUNT_ERROR:
      return state.set('claimsListActiveMessagesCountError', true).set('claimsListActiveMessagesCountLoading', false);
    case actions.SET_WORKLIST_HEADER_ACTIVE_MESSAGES_COUNT:
      return state.set('worklistHeaderActiveMessagesCount', action.count);
    case actions.CREATE_WORKLIST_CLAIM_MESSAGE:
      return state.set('createWorklistClaimMessageLoading', true).set('createWorklistClaimMessageError', false);
    case actions.CREATE_WORKLIST_CLAIM_MESSAGE_SUCCESS:
      return state.set('createWorklistClaimMessageLoading', false);
    case actions.CREATE_WORKLIST_CLAIM_MESSAGE_ERROR:
      return state.set('createWorklistClaimMessageLoading', false).set('createWorklistClaimMessageError', true);
    case actions.SET_WORKLIST_CLAIM_MESSAGES_FILTER:
      return state.set('worklistClaimMessagesFilter', action.filter).set('worklistClaimMessagesFilterIsChanged', true);
    case actions.SET_WORKLIST_CLAIM_MESSAGES_FILTER_TO_DEFAULT:
      return state
        .set('worklistClaimMessagesFilter', state.get('worklistClaimMessagesDefaultFilter'))
        .set('worklistClaimMessagesFilterIsChanged', false);
    case actions.UPDATE_WORKLIST_CLAIM_MESSAGE:
      return state.set('worklistClaimMessagesFilterIsChanged', false).set('updateWorklistClaimMessageLoading', true);
    case actions.UPDATE_WORKLIST_CLAIM_MESSAGE_SUCCESS:
      return state.set('updateWorklistClaimMessageLoading', false);
    case actions.UPDATE_WORKLIST_CLAIM_MESSAGE_ERROR:
      return state.set('updateWorklistClaimMessageError', true).set('updateWorklistClaimMessageLoading', false);
    case actions.CLEAR_WORKLIST_CLAIM_MESSAGES:
      return state.set('worklistClaimMessages', null);
    case actions.SET_IS_CREATE_MESSAGE_TOUCHED:
      return state.set('isCreateMessageTouched', action.isTouched);
    case actions.CLEAR_IS_CREATE_MESSAGE_TOUCHED:
      return state.set('isCreateMessageTouched', false);
    case actions.SET_NEW_MESSAGE_ATTACHMENTS:
      return state.set('newMessageAttachments', action.attachments);
    case actions.CLEAR_NEW_MESSAGE_ATTACHMENTS:
      return state.set('newMessageAttachments', []);
    case actions.SET_IS_NEW_MESSAGE_ATTACHMENTS_UPLOAD_LOADING:
      return state.set('isNewMessageAttachmentsUploadLoading', action.isLoading);
    default:
      return state;
  }
};

export default reducer;
