import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { Navigate, useLocation } from 'react-router-dom';
import OptInModal from '../OptInModal/OptInModal';
import DefaultLogo from '../Logo/DefaultLogo.png';
import ImportContainer from '../../containers/Import/ImportContainer';

const AuthRoute = ({ component: Component, config, isLoggedIn, userRights, user, confirmOptInLoading }) => {
  const location = useLocation();

  useEffect(() => {
    const agencyLogo = config && config.tenant_logo && config.tenant_logo.url;
    if (isLoggedIn) {
      let link = document.querySelector('link[rel~="icon"]');
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = agencyLogo || DefaultLogo;
    }
  }, [config]);

  if (!isLoggedIn) {
    return <Navigate to="/signin" state={{ from: location }} />;
  }

  const renderContentWhenLoggedIn = () => {
    if (isLoggedIn === true && !Object.keys(userRights).length) {
      return React.Fragment;
    }

    if (!confirmOptInLoading && user && !user.terms_of_use_approved_at) {
      return <OptInModal config={config} user={user} confirmOptInLoading={confirmOptInLoading} />;
    }

    // The ImportContainer should be for now rendered outside the main navigation (without a navigation header)
    // This is the reason why we are checking the pathname here and not in the AppRouter
    if (location.pathname === '/import') {
      return <ImportContainer />;
    }

    return <Component />;
  };

  return renderContentWhenLoggedIn();
};

const mapStateToProps = (state) => ({
  config: state.Config.get('config'),
  confirmOptInLoading: state.Users.get('confirmOptInLoading'),
  isLoggedIn: state.Auth.get('isLoggedIn'),
  user: state.Auth.get('user'),
  userRights: state.Auth.get('userRights')
});

export default connect(mapStateToProps)(AuthRoute);
