import AntSelect from 'antd/es/select';
import styled from 'styled-components';
import React from 'react';
import { windowMaxWidth } from '../Layout/breakpoints';

const StyledSelect = styled(AntSelect)`
  &.ant-select {
    width: 100%;
    height: 40px;

    &.ant-select-focused {
      .ant-select-selector {
        border: 1px solid var(--primary-oqio-blue) !important;
        box-shadow: var(--box-shadow-oqio-blue) !important;
      }
    }
    .ant-select-selector {
      align-items: center;
      background-color: var(--greyscale-porcelain) !important;
      background-image: none;
      border: 1px solid var(--greyscale-concrete);
      border-radius: var(--border-radius-small);
      color: var(--greyscale-blackish);
      cursor: pointer;
      display: flex;
      font-size: var(--font-size-regular);
      font-weight: var(--font-weight-regular);
      height: 40px;
      width: 100%;

      &:hover {
        border: 1px solid var(--primary-oqio-blue);
      }

      .ant-select-arrow .ant-select-arrow-icon {
        color: var(--primary-oqio-blue);
      }

      .ant-select-selection-search {
        bottom: auto;
        left: 11px;
        position: absolute;
        right: 11px;
        top: 6px;
      }

      .ant-select-selection-placeholder {
        color: var(--greyscale-shade);
      }
    }

    &.ant-select-disabled {
      .ant-select-selection--single {
        background-color: var(--greyscale-porcelain);
        border: 1px solid var(--greyscale-dark-white);
        color: var(--greyscale-concrete);
        cursor: default;
        opacity: 0.8;
      }

      .ant-select-selector {
        border: 1px solid var(--greyscale-dark-white);
        color: var(--greyscale-concrete);
        cursor: default;

        .ant-select-selection-placeholder {
          color: var(--greyscale-concrete);
        }

        input {
          cursor: default;
        }

        &:hover {
          box-shadow: none;
        }
      }

      i {
        color: var(--greyscale-concrete) !important;
      }

      svg {
        path {
          stroke: var(--greyscale-concrete);
        }
        circle {
          stroke: var(--greyscale-concrete);
        }
      }
    }

    &.ant-select-borderless {
      height: 36px;

      &.ant-select-focused {
        .ant-select-selector {
          border: 0 !important;
          box-shadow: none !important;
        }
      }

      &.ant-select-single {
        .ant-select-selector {
          background-color: var(--greyscale-white) !important;
          border: none;
          padding-top: 0;
          height: 36px;

          .ant-select-selection-item {
            align-items: center;
            display: flex;
            flex-wrap: wrap;
          }

          &:hover {
            border: none;
          }
        }
      }

      &.select {
        &-font {
          &-big {
            .ant-select-selector {
              height: 36px;
              padding-left: 10px;
              transition: none;

              &:hover {
                color: var(--greyscale-shade);
                transition: none;
              }
            }
            .ant-select-selection-item {
              font-size: var(--font-size-heading-3);
              font-weight: var(--font-weight-medium);
              line-height: var(--font-line-height-medium);
            }
            &.ant-select-open {
              .ant-select-selector {
                color: var(--greyscale-shade);
                transition: none;
              }
            }
          }

          &-grey {
            .ant-select-selector {
              color: var(--greyscale-shade);
              height: 36px;
              padding-left: 0;
              transition: none;

              &:hover {
                color: var(--greyscale-concrete);
                transition: none;
              }
            }
            .ant-select-selection-item {
              font-size: var(--font-size-regular);
              line-height: var(--font-line-height-regular);
            }
            &.ant-select-open {
              .ant-select-selector {
                color: var(--greyscale-concrete);
                transition: none;
              }
            }
          }
        }
      }
    }
    .ant-select-selection-item {
      .result {
        span:not(.highlight):not(.non-highlight) {
          margin-left: 5px;
          width: 20px;
          display: inline-block;

          svg {
            position: absolute;
            top: 9px;

            circle,
            path {
              stroke: var(--primary-oqio-green);
              transition: all 0.2s ease-in-out;
            }
            &:hover {
              cursor: pointer;
              circle {
                fill: var(--primary-oqio-green);
                transition: all 0.2s ease-in-out;
              }
              path {
                stroke: var(--greyscale-white);
                transition: all 0.2s ease-in-out;
              }
            }
          }
        }
      }
    }

    &.search-select-input {
      .ant-select-selection-search {
        top: 8px;
        height: 20px;

        input {
          display: none;
          &[aria-expanded="true"] {
            display: block;
        }
      }
    }
  }

  &.ant-select-status-error:not(.ant-select-disabled):not(.ant-select-customize-input):not(.ant-pagination-size-changer) {
    .ant-select-selector {
      box-shadow: var(--box-shadow-oqio-red);
    }

    &:hover {
      .ant-select-selector {
        box-shadow: var(--box-shadow-oqio-blue);
        border-color: var(--primary-oqio-blue);
      }
    }
  }

  &.claims-sort-select-popup {
    margin: 20px;
  }

  .ant-select-itom-option {
    &.ant-select-item-option-active {
      background-color: var(--greyscale-porcelain);
    }
  }

  &.tabs-navigation {
    display: none;
  }

  @media ${windowMaxWidth.mobile} {
    &.tabs-navigation {
      margin-bottom: 10px;
      display: flex;

      .ant-select-selector {
        padding: 0;
      }

      .ant-select-arrow {
        right: 0;
      }
    }
  }
`;

const getCustomSuffixIcon = () => (
  <svg width="14" height="9" viewBox="0 0 14 9" fill="none">
    <path
      d="m 1.0316302,1.160584 6,5.9999997 5.9999988,-5.9999997"
      stroke="var(--greyscale-blackish)"
      strokeWidth="1.5"
      id="path2"
    />
  </svg>
);

const ExtendedSelect = (props) => (
  <StyledSelect
    placeholder={props.placeholder || 'Bitte wählen'}
    suffixIcon={props.suffixIcon || getCustomSuffixIcon()}
    {...props}
    ref={props.innerRef || undefined}
  />
);

ExtendedSelect.Option = (props) => <AntSelect.Option {...props} />;

export default ExtendedSelect;
