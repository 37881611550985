import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import { onLoadBuildingToPolicies, onCreateBuildingToPolicies, onDeleteManyBuildingToPolicies } from './apiCalls';
import actions from './actions';
import buildingsActions from '../buildings/actions';
import policiesActions from '../policies/actions';
import renderNotification from '../../helpers/notifications/renderNotification';
import { NOTIFICATION_MESSAGES } from '../../localization/Notifications/dictionary';

export function* createBuildingToPoliciesSaga({ params, showNotification }) {
  try {
    const response = yield call(onCreateBuildingToPolicies, params);
    if (response.data) {
      const { building_to_policies_create_success } = NOTIFICATION_MESSAGES;
      if (showNotification) {
        renderNotification({ type: 'success', message: building_to_policies_create_success });
      }
      yield put(actions.createBuildingToPoliciesSuccess(response.data));
    } else {
      yield put(actions.createBuildingToPoliciesError());
    }
  } catch (error) {
    yield put(actions.createBuildingToPoliciesError());
  }
}

export function* deleteManyBuildingToPoliciesSaga({ params }) {
  try {
    const response = yield call(onDeleteManyBuildingToPolicies, params);
    if (response.status === 200) {
      const { building_to_policies_delete_success } = NOTIFICATION_MESSAGES;
      renderNotification({ type: 'success', message: building_to_policies_delete_success });
      yield put(actions.deleteManyBuildingToPoliciesSuccess(response.data));
    } else {
      yield put(actions.deleteManyBuildingToPoliciesError());
    }
  } catch (error) {
    yield put(actions.deleteManyBuildingToPoliciesError());
  }
}

export function* getBuildingToPoliciesSaga({ params }) {
  try {
    const response = yield call(onLoadBuildingToPolicies, { ...params, paging: false });
    if (response.data) {
      yield put(
        actions.getBuildingToPoliciesSuccess(response.data, {
          perPage: response.headers.perpage,
          total: response.headers.total
        })
      );
    } else {
      yield put(actions.getBuildingToPoliciesError());
    }
  } catch (error) {
    yield put(actions.getBuildingToPoliciesError());
  }
}

const getBuilding = (state) => state.Buildings.get('building');
const getSinglePolicy = (state) => state.Policies.get('policy');

export function* reloadBuildingToPolicies() {
  const building = yield select(getBuilding);
  const policy = yield select(getSinglePolicy);
  const item = building || policy;
  if (!item) {
    return;
  }
  const { id } = item;
  if (building) {
    yield put(actions.getBuildingToPolicies({ building_id: [id] }));
    yield put(buildingsActions.getConnectedPolicies([id]));
    return;
  }
  yield put(actions.getBuildingToPolicies({ policy_id: [id] }));
  yield put(policiesActions.getConnectedBuildings({ policy_id: [id], paging: false }));
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE_BUILDING_TO_POLICIES, createBuildingToPoliciesSaga),
    takeEvery(actions.CREATE_BUILDING_TO_POLICIES_SUCCESS, reloadBuildingToPolicies),
    takeEvery(actions.DELETE_MANY_BUILDING_TO_POLICIES, deleteManyBuildingToPoliciesSaga),
    takeEvery(actions.DELETE_MANY_BUILDING_TO_POLICIES_SUCCESS, reloadBuildingToPolicies),
    takeEvery(actions.GET_BUILDING_TO_POLICIES, getBuildingToPoliciesSaga)
  ]);
}
