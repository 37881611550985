import React, { lazy } from 'react';
import { Routes, Route, Navigate, useLocation, useParams } from 'react-router-dom';
import { connect } from 'react-redux';
import queryString from 'query-string';
import { Layout } from 'antd';
import Header from '../../components/Headers/Header';
import MainWrapper from './MainWrapper.style';
import withSuspense from '../../hoc/withSuspense';
import IntercomController from '../Chat/IntercomContainer';

const AdministrationContainer = lazy(() => import('../Administration/AdministrationContainer'));
const BuildingsContainer = lazy(() => import('../Buildings/BuildingsContainer'));
const BuildingDetails = lazy(() => import('../../components/BuildingDetails/BuildingDetails'));
const ClaimDetails = lazy(() => import('../../components/ClaimDetails/ClaimDetails'));
const ClaimsContainer = lazy(() => import('../Claims/ClaimsContainer'));
const ReportingContainerWrapper = lazy(() => import('../Reporting/ReportingContainerWrapper'));
const PoliciesContainer = lazy(() => import('../Policies/PoliciesContainer'));
const PolicyDetails = lazy(() => import('../../components/PolicyDetails/PolicyDetails'));
const SidebarContainer = lazy(() => import('../Sidebar/SidebarContainer'));

// Redirect to the main tab of the claim details page
const RedirectToClaimDetailsMainTab = () => {
  const { claimId } = useParams();
  return <Navigate to={`/claim/${claimId}/main`} replace />;
};

/**
 * Functional component that sets up the main application routes.
 * It includes lazy-loaded components and route handling for different parts of the application.
 * @property {Object} user - the current logged-in user information.
 */
const AppRouter = ({ user }) => {
  const location = useLocation();
  const queryParams = queryString.parse(location.search);

  return (
    <MainWrapper>
      <IntercomController user={user}>
        <Layout className="page-with-header" id="page-modal-wrapper">
          <Header />
          <Layout.Content className="page-wrapper">
            {withSuspense(<SidebarContainer />)}
            <div className="page-content">
              <Routes>
                <Route path="/" element={<Navigate to="/claims/list" replace />} />
                <Route path="claims/:tab" element={withSuspense(<ClaimsContainer queryParams={queryParams} />)} />
                <Route path="claim/:claimId" element={<RedirectToClaimDetailsMainTab />} />
                <Route path="claim/:claimId/:tab?/:settlementId?" element={withSuspense(<ClaimDetails />)} />
                <Route
                  path="buildings/:view"
                  element={withSuspense(<BuildingsContainer queryParams={queryParams} />)}
                />
                <Route
                  path="building/:buildingId/:tab"
                  element={withSuspense(<BuildingDetails queryParams={queryParams} />)}
                />
                <Route path="policies/:view" element={withSuspense(<PoliciesContainer queryParams={queryParams} />)} />
                <Route
                  path="policy/:policyId/:tab"
                  element={withSuspense(<PolicyDetails queryParams={queryParams} />)}
                />
                <Route path="reporting" element={<Navigate to="/reporting/claims" replace />} />
                <Route path="reporting/*" element={withSuspense(<ReportingContainerWrapper />)} />
                <Route path="administration/*" element={withSuspense(<AdministrationContainer />)} />
                <Route path="*" element={<Navigate to="/pageNotFound" replace />} />
              </Routes>
            </div>
          </Layout.Content>
        </Layout>
      </IntercomController>
    </MainWrapper>
  );
};

const mapStateToProps = (state) => ({
  user: state.Auth.get('user')
});

export default connect(mapStateToProps)(AppRouter);
