import styled from 'styled-components';
import { Heading4 } from '../../Heading/Heading';

const UploadDocumentsWrapper = styled.div`
  background-color: #f5f6fa;
  height: calc(100% - 220px);
  overflow: auto;
  scrollbar-color: rgba(136, 136, 136, 0.3) transparent;
  scrollbar-width: thin;
`;

const AttachmentSectionWrapper = styled.div`
  border-bottom: 1px solid #eeeeee;
  height: 100%;
  overflow-y: auto;
  padding: 20px 20px 40px 20px;
  scrollbar-color: rgba(136, 136, 136, 0.3) transparent;
  scrollbar-width: thin;
`;

const UploadDocumentsUpdateOverlay = styled.div`
  align-items: center;
  backdrop-filter: blur(10px);
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: 2;
`;

const UploadDocumentsUploadProgressWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
`;

const UploadDocumentsUploadProgressMessage = styled.div`
  font-size: 16px;
  font-style: italic;
  font-weight: normal;
  line-height: 20px;
  margin-top: 20px;
  text-align: center;
`;

const UploadDocumentsUploadDropzoneWrapper = styled.div`
  background-color: #ffffff;
  border: 2px dashed #627fff;
  border-radius: var(--border-radius-small);
  cursor: pointer;
  display: flex;
  height: 210px;
  justify-content: center;
  margin: 10px 0;
  padding: 20px;
  transition:
    border 0.2s ease-in,
    box-shadow 0.2s ease-out;

  &:hover {
    background-color: #f5f5f5;
  }

  &:active {
    background-color: #f0f0f0;
  }

  &:focus {
    outline: none;
  }

  &:hover,
  &:active,
  &:focus {
    border-color: #627fff;
  }
`;

// Styled for the file upload section

const UploadDocumentsUploadDropzoneTextWrapper = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  font-weight: 500;
  justify-content: center;
`;

const UploadDocumentsHighlightedText = styled.span`
  color: #627fff;
  margin-left: 5px;

  &:hover {
    text-decoration: underline;
  }
`;

const UploadDocumentsUploadDropzoneText = styled.span``;

const UploadDocumentsConstraints = styled.div`
  color: var(--greyscale-shade);
  font-size: var(--font-size-small);
  line-height: --font-line-height-small;
  margin: 20px 0;
`;

const UploadDocumentsEmptyStateWrapper = styled.div`
  align-items: center;
  display: flex;
  height: 100%;
  justify-content: center;
`;

const ModalWrapper = styled.div`
  min-height: 200px;
  overflow-y: auto;
  padding: 20px 40px;
`;

const UploadDocumentsModalFileListHeader = styled.div`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 10px;
`;

const UploadDocumentsModalFileListHeaderText = styled(Heading4)`
  font-weight: normal;
  margin: 0;
`;

const UploadDocumentsModalFileListCounter = styled.div`
  background-color: var(--greyscale-concrete);
  border-radius: 10px;
  color: var(--greyscale-white);
  font-size: var(--font-size-small);
  font-weight: var(--font-weight-semi-bold);
  line-height: var(--font-line-height-small);
  padding: 2px 6px;
`;

const UploadDocumentsModalDescription = styled.div`
  color: var(--greyscale-blackish);
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  margin-bottom: 20px;
`;

export const UploadDocumentsModalLayout = {
  AttachmentSectionWrapper,
  UploadDocumentsUpdateOverlay,
  UploadDocumentsUploadProgressMessage,
  UploadDocumentsUploadProgressWrapper,
  UploadDocumentsWrapper,
  UploadDocumentsUploadDropzoneWrapper,
  UploadDocumentsUploadDropzoneText,
  UploadDocumentsUploadDropzoneTextWrapper,
  UploadDocumentsHighlightedText,
  UploadDocumentsConstraints,
  UploadDocumentsEmptyStateWrapper,
  ModalWrapper,
  UploadDocumentsModalFileListHeader,
  UploadDocumentsModalFileListHeaderText,
  UploadDocumentsModalFileListCounter,
  UploadDocumentsModalDescription
};
