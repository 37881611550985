import { all, takeEvery, put, delay, select } from 'redux-saga/effects';
import actions from './actions';
import sidebarActions from '../sidebar/actions';

const getSidebarDefaultLocation = (state) => state.Sidebar.get('sidebarDefaultLocation');
const getIsSidebarOpen = (state) => state.Layout.get('isSidebarOpen');

export function* toggleSidebarSaga() {
  const sidebarDefaultLocation = yield select(getSidebarDefaultLocation);
  const isSidebarOpen = yield select(getIsSidebarOpen);
  yield put(actions.toggleSidebarSuccess());
  // When the sidebar closes, wait for the transition to finish before resetting the location
  if (isSidebarOpen) {
    yield delay(400);
    yield put(sidebarActions.setSidebarLocation(sidebarDefaultLocation));
  }
}

export default function* rootSaga() {
  yield all([takeEvery(actions.TOGGLE_SIDEBAR, toggleSidebarSaga)]);
}
