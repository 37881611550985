import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import queryString from 'query-string';
import FileSaver from 'file-saver';
import {
  onCreateBuildings,
  onUpdateBuildings,
  onGetBuildings,
  onGetBuilding,
  onExportBuildings,
  onGetBuildingsHousingStocks,
  onGetBuildingsCompanyCodes,
  onGetBuildingsEconomicUnits,
  onDeleteBuildings
} from './apiCalls';
import { onLoadAll } from '../policies/apiCalls';
import actions from './actions';
import buildingToPoliciesActions from '../BuildingToPolicies/actions';
import renderNotification from '../../helpers/notifications/renderNotification';
import newEntityNotification from '../../helpers/notifications/newEntityNotification';
import { NOTIFICATION_MESSAGES } from '../../localization/Notifications/dictionary';
import { getFileNameFromApiResponseHeader } from '../../helpers/utility';

export function* createBuildingsSaga({ buildings, linkedPolicies, callback, navigate }) {
  try {
    const response = yield call(onCreateBuildings, buildings);
    if (response.data) {
      if (linkedPolicies && linkedPolicies.length > 0) {
        const { id } = response.data[0];
        const buildingToPolicies = linkedPolicies.map((policy) => {
          return { building_id: id, policy_id: policy.id };
        });
        yield put(buildingToPoliciesActions.createBuildingToPolicies(buildingToPolicies, false));
      }
      if (callback && typeof callback === 'function') {
        callback();
      }
      newEntityNotification({
        entityType: 'building',
        entityId: response.data[0].id,
        navigate
      });
      yield put(actions.createBuildingsSuccess(response.data));
    } else {
      yield put(actions.createBuildingsError());
    }
  } catch (error) {
    yield put(actions.createBuildingsError());
  }
}

export function* updateBuildingsSaga({ buildings, callback }) {
  try {
    const response = yield call(onUpdateBuildings, buildings);
    if (response.data) {
      yield put(actions.updateBuildingsSuccess(response.data));

      if (callback && typeof callback === 'function') {
        callback();
      }
    } else {
      yield put(actions.updateBuildingsError());
    }
  } catch (error) {
    yield put(actions.updateBuildingsError());
  }
}

export function* deleteBuildingsSaga({ payload, callback }) {
  try {
    const response = yield call(onDeleteBuildings, payload);
    if (response.status === 200) {
      yield put(actions.deleteBuildingsSuccess(response.data));

      if (callback && typeof callback === 'function') {
        callback();
      }
      const { building_delete_success } = NOTIFICATION_MESSAGES;
      renderNotification({ type: 'success', message: building_delete_success });
    } else {
      yield put(actions.deleteBuildingsError());
    }
  } catch (error) {
    yield put(actions.deleteBuildingsError());
  }
}

export function* getBuildingsSaga({ params }) {
  try {
    const response = yield call(onGetBuildings, params);
    if (response.data) {
      const buildingsSuccessData = {
        perPage: response.headers.perpage,
        total: response.headers.total
      };
      yield all([put(actions.getBuildingsSuccess(response.data, buildingsSuccessData))]);
    } else {
      yield put(actions.getBuildingsError());
    }
  } catch (error) {
    yield put(actions.getBuildingsError());
  }
}

export function* getAllBuildingsSaga() {
  try {
    const filters = yield select(getActiveFilters);
    const params = queryString.parse(filters);
    params.paging = false;
    const response = yield call(onGetBuildings, params);
    if (response.data) {
      yield put(actions.getAllBuildingsSuccess(response.data));
    } else {
      yield put(actions.getAllBuildingsError());
    }
  } catch (error) {
    yield put(actions.getAllBuildingsError());
  }
}

export function* getBuildingSaga({ buildingId }) {
  try {
    const response = yield call(onGetBuilding, buildingId);
    if (response.data) {
      yield put(actions.getBuildingSuccess(response.data));
    } else {
      yield put(actions.getBuildingError());
    }
  } catch (error) {
    yield put(actions.getBuildingError());
  }
}

export function* exportBuildingsSaga({ params }) {
  try {
    const getBuildingsResponse = yield call(onGetBuildings, { ...params, paging: false });
    const buildingsIds = getBuildingsResponse.data.map((building) => building.id);
    const response = yield call(onExportBuildings, buildingsIds);

    if (response.status === 200) {
      const filename = getFileNameFromApiResponseHeader(response.headers, '.xlsx');

      // only IE does support this method
      if (window.navigator.msSaveOrOpenBlob) {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        window.navigator.msSaveBlob(blob, filename);
      } else {
        const blob = new File([response.data], filename, {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64'
        });
        FileSaver.saveAs(blob);
      }

      yield put(actions.exportBuildingsSuccess());
    } else {
      yield put(actions.exportBuildingsError());
    }
  } catch {
    yield put(actions.exportBuildingsError());
  }
}

export function* getBuildingsCompanyCodesSaga() {
  try {
    const response = yield call(onGetBuildingsCompanyCodes);
    if (response.data) {
      yield put(actions.getBuildingsCompanyCodesSuccess(response.data));
    } else {
      yield put(actions.getBuildingsCompanyCodesError());
    }
  } catch (error) {
    yield put(actions.getBuildingsCompanyCodesError());
  }
}

export function* getBuildingsEconomicUnitsSaga() {
  try {
    const response = yield call(onGetBuildingsEconomicUnits);
    if (response.data) {
      yield put(actions.getBuildingsEconomicUnitsSuccess(response.data));
    } else {
      yield put(actions.getBuildingsEconomicUnitsError());
    }
  } catch (error) {
    yield put(actions.getBuildingsEconomicUnitsError());
  }
}

export function* getBuildingsHousingStocksSaga() {
  try {
    const response = yield call(onGetBuildingsHousingStocks);
    if (response.data) {
      yield put(actions.getBuildingsHousingStocksSuccess(response.data));
    } else {
      yield put(actions.getBuildingsHousingStocksError());
    }
  } catch (error) {
    yield put(actions.getBuildingsHousingStocksError());
  }
}

export function* getBuildingConnectedPoliciesSaga({ buildingId }) {
  try {
    const response = yield call(onLoadAll, { building_id: buildingId, paging: false });
    if (response.data) {
      yield put(actions.getConnectedPoliciesSuccess(response.data));
    } else {
      yield put(actions.getConnectedPoliciesError());
    }
  } catch (error) {
    yield put(actions.getConnectedPoliciesError());
  }
}

export const getBuilding = (state) => state.Buildings.get('building');
export const getActiveFilters = (state) => state.Filters.get('buildingsActiveFilters');

/**
 * Re-fetches the building after an update on its fields
 */
export function* reloadBuildingSaga() {
  const building = yield select(getBuilding);
  const { id } = building;
  yield put(actions.getBuilding(id));
}

/**
 * re-fetches the buildings after creating a new one
 * the update must respect the current filters
 */
export function* reloadAllBuildingsSaga() {
  const filters = yield select(getActiveFilters);
  const params = queryString.parse(filters);
  yield all([
    put(actions.getBuildings(params)),
    // The filter options should be fetched on every buildings reload,
    // as creating or editing buildings might affect the filter options.
    put(actions.getBuildingsHousingStocks()),
    put(actions.getBuildingsCompanyCodes()),
    put(actions.getBuildingsEconomicUnits())
  ]);
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.CREATE_BUILDINGS, createBuildingsSaga),
    takeEvery(actions.CREATE_BUILDINGS_SUCCESS, reloadAllBuildingsSaga),
    takeEvery(actions.UPDATE_BUILDINGS, updateBuildingsSaga),
    takeEvery(actions.UPDATE_BUILDINGS_SUCCESS, reloadBuildingSaga),
    takeEvery(actions.DELETE_BUILDINGS, deleteBuildingsSaga),
    takeEvery(actions.GET_BUILDINGS, getBuildingsSaga),
    takeEvery(actions.GET_BUILDING, getBuildingSaga),
    takeEvery(actions.GET_ALL_BUILDINGS, getAllBuildingsSaga),
    takeEvery(actions.EXPORT_BUILDINGS, exportBuildingsSaga),
    takeEvery(actions.GET_BUILDINGS_HOUSING_STOCKS, getBuildingsHousingStocksSaga),
    takeEvery(actions.GET_BUILDINGS_COMPANY_CODES, getBuildingsCompanyCodesSaga),
    takeEvery(actions.GET_BUILDINGS_ECONOMIC_UNITS, getBuildingsEconomicUnitsSaga),
    takeEvery(actions.GET_CONNECTED_POLICIES, getBuildingConnectedPoliciesSaga)
  ]);
}
