import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import SimpleLayoutStyleWrapper from './SimpleLayout.style';
import DefaultLogo from '../../components/Logo/DefaultLogo.png';
import background from './oqio-background.jpg';

const Background = styled.img`
  bottom: 0;
  height: 100vh;

  @supports (height: 100lvh;) {
    height: 100lvh;
  }

  left: 0;
  object-fit: cover;
  position: absolute;
  right: 0;
  top: 0;
  width: 100%;
  z-index: 1;
`;

const LogoWrapper = styled(Link)`
  align-items: center;
  display: flex;
  left: 30px;
  position: absolute;
  top: 10px;
  max-width: 40vw;
  z-index: 5;

  &:focus,
  &:active {
    border: none;
    outline: 0;
  }
`;

const LogoIcon = styled.img`
  margin-right: 10px;
  max-height: 40px;
  max-width: 40px;
`;

const LogoTitle = styled.div`
  color: var(--greyscale-white);
  display: block;
  font-size: var(--font-size-regular);
  font-weight: var(--font-weight-medium);
`;

const SimpleLayout = ({ config, component: Component, ...props }) => {
  useEffect(() => {
    handleTabIcon();
  }, [config]);

  const handleTabIcon = () => {
    const agencyLogo = config && config.tenant_logo && config.tenant_logo.url;
    if (agencyLogo) {
      let link = document.querySelector('link[rel~="icon"]');
      if (!link) {
        link = document.createElement('link');
        link.rel = 'icon';
        document.getElementsByTagName('head')[0].appendChild(link);
      }
      link.href = agencyLogo || DefaultLogo;
    }
  };

  const agencyLogo = config && config.tenant_logo && config.tenant_logo.url;
  const agencyName = config && config.tenant_name;

  return (
    <SimpleLayoutStyleWrapper>
      <LogoWrapper to="/">
        <LogoIcon src={agencyLogo || DefaultLogo} alt="Agency Logo" />
        <LogoTitle>{agencyName || 'Ihr Versicherungsportal'}</LogoTitle>
      </LogoWrapper>
      <Component {...props} />
      <div className="terms-menu">
        <a
          className={config && config.imprint ? 'oqio-link valid-link' : 'oqio-link'}
          href={config && config.imprint ? config.imprint.url : '#'}
          target={config && config.imprint ? '_blank' : ''}
          rel="noopener noreferrer"
        >
          Impressum
        </a>
        <a
          className={config && config.terms_of_use ? 'oqio-link valid-link' : 'oqio-link'}
          href={config && config.terms_of_use ? config.terms_of_use.url : '#'}
          target={config && config.terms_of_use ? '_blank' : ''}
          rel="noopener noreferrer"
        >
          Nutzungsbedingungen
        </a>
        <a
          className={config && config.privacy_statement ? 'oqio-link valid-link' : 'oqio-link'}
          href={config && config.privacy_statement ? config.privacy_statement.url : '#'}
          target={config && config.privacy_statement ? '_blank' : ''}
          rel="noopener noreferrer"
        >
          Datenschutzinformationen
        </a>
      </div>
      <Background src={background} alt="building" />
    </SimpleLayoutStyleWrapper>
  );
};

const mapStateToProps = (state) => ({
  config: state.Config.get('config')
});

export default connect(mapStateToProps, {})(SimpleLayout);
