import { all, takeEvery, put, call, select } from 'redux-saga/effects';
import FileSaver from 'file-saver';
import {
  onCreateSettlement,
  onUpdateSettlement,
  onGetSettlements,
  onLoadCutbackReasons,
  onCreateCutbackReason,
  onDownloadSettlementSummary,
  onExportSettlements
} from './apiCalls';
import actions from './actions';
import claimActions from '../claims/actions';
import worklistClaimsActions from '../worklistClaims/actions';
import renderNotification from '../../helpers/notifications/renderNotification';
import { NOTIFICATION_MESSAGES } from '../../localization/Notifications/dictionary';
import fileUploadActions from '../fileUpload/actions';
import { ATTACHMENT_MAX_SIZES } from '../../consts/consts';
import { getFileNameFromApiResponseHeader } from '../../helpers/utility';

export function* getAllSettlementsSaga({ params }) {
  try {
    const response = yield call(onGetSettlements, params);
    if (response.data) {
      yield put(actions.getAllSettlementsSuccess(response.data));
    } else {
      yield put(actions.getAllSettlementsError());
    }
  } catch (error) {
    yield put(actions.getAllSettlementsError());
  }
}

export function* getSettlementsByClaimIdSaga({ claimId }) {
  try {
    const params = { claim_id: claimId, paging: false };
    const response = yield call(onGetSettlements, params);
    if (response.data) {
      yield put(actions.getSettlementsByClaimIdSuccess(response.data));
    } else {
      yield put(actions.getSettlementsByClaimIdError());
    }
  } catch (error) {
    yield put(actions.getSettlementsByClaimIdError());
  }
}

export function* createSettlement({ payload, callback }) {
  try {
    const response = yield call(onCreateSettlement, payload.settlement);
    if (response.status === 200) {
      yield put(actions.createSettlementSuccess(response.data));
      yield put(claimActions.getClaimHistory(response.data.claim_id));
      yield put(worklistClaimsActions.getWorklistClaimMessages(response.data.claim_id));
      callback();
    } else {
      yield put(actions.createSettlementError(response));
    }
  } catch (error) {
    yield put(actions.createSettlementError(error));
  }
}

export function* updateSettlement({ settlement, callback }) {
  try {
    const response = yield call(onUpdateSettlement, settlement);
    if (response.data) {
      yield put(actions.updateSettlementSuccess(response.data));
      yield put(claimActions.getClaimHistory(response.data.claim_id));
      yield put(worklistClaimsActions.getWorklistClaimMessages(response.data.claim_id));
      if (typeof callback === 'function') {
        callback();
      }
    } else {
      yield put(actions.updateSettlementError());
    }
  } catch (error) {
    yield put(actions.updateSettlementError());
  }
}

export function* getSettlementInvoiceCutbackReasons() {
  try {
    const response = yield call(onLoadCutbackReasons);
    if (response.data) {
      yield put(actions.loadCutbackReasonsSuccess(response.data));
    } else {
      yield put(actions.loadCutbackReasonsError());
    }
  } catch {
    yield put(actions.loadCutbackReasonsError());
  }
}

export function* createSettlementCutbackReason({ cutbackReason }) {
  try {
    const response = yield call(onCreateCutbackReason, cutbackReason);
    if (response.data) {
      yield put(actions.createCutbackReasonSuccess());
      const { settlement_new_cutback_reason_create_success } = NOTIFICATION_MESSAGES;
      renderNotification({ type: 'success', message: settlement_new_cutback_reason_create_success });
      yield put(actions.loadCutbackReasons());
    } else {
      yield put(actions.createCutbackReasonError());
    }
  } catch {
    yield put(actions.createCutbackReasonError());
  }
}

export function* reloadSettlements({ settlement: { claim_id } }) {
  yield put(actions.getSettlementsByClaimId(claim_id));
}

const getSettlements = (state) => state.Settlements.get('settlements');

export function* loadPDFSettlementSummary({ settlementId, isMessage }) {
  try {
    const response = yield call(onDownloadSettlementSummary, settlementId);
    if (response.data) {
      const blobToFile = (theBlob, fileName) => {
        const b = theBlob;
        b.lastModifiedDate = new Date();
        b.name = fileName;
        return b;
      };
      let pdfSettlementFile;

      const isEdgeBrowser = navigator.userAgent.indexOf('Edge') > -1;
      const fileName = getFileNameFromApiResponseHeader(response.headers, '.pdf');
      const blob = new Blob([response.data], { type: 'application/pdf' });

      // only IE or old Edge do support this method
      if (isEdgeBrowser || window.navigator.msSaveOrOpenBlob) {
        pdfSettlementFile = blobToFile(response.data, fileName);
      } else {
        pdfSettlementFile = new File([blob], fileName, { type: 'application/pdf' });
      }

      yield put(actions.loadSettlementPdfSummarySuccess(pdfSettlementFile));

      if (isMessage) {
        const isFileBiggerThan20MB = pdfSettlementFile.size > 20971520;
        if (isFileBiggerThan20MB) {
          yield put(worklistClaimsActions.setIsNewMessageAttachmentsUploadLoading(false));

          const settlements = yield select(getSettlements);
          const currentSettlement = settlements.find((settlement) => settlement.id === settlementId);

          const settlement_pdf_report_too_big_error = `Die Rechnung '${currentSettlement.external_id}' ist zu groß, um sie einer Nachricht hinzuzufügen. Die maximale Dateigröße beträgt ${ATTACHMENT_MAX_SIZES.megaByte} MB.`;

          renderNotification({ type: 'error', message: settlement_pdf_report_too_big_error });
        } else {
          yield put(
            fileUploadActions.uploadFileRequestForMessageAttachments(pdfSettlementFile, settlementId, 'settlement')
          );
        }
      }
    } else {
      yield put(actions.loadSettlementPdfSummaryError());
    }
  } catch (error) {
    yield put(actions.loadSettlementPdfSummaryError());
  }
}

export function* exportSettlementsSaga({ params }) {
  try {
    const response = yield call(onExportSettlements, params);
    if (response.status === 200) {
      const filename = getFileNameFromApiResponseHeader(response.headers, '.xlsx');

      if (window.navigator.msSaveOrOpenBlob) {
        const blob = new Blob([response.data], { type: 'application/pdf' });
        window.navigator.msSaveBlob(blob, filename);
      } else {
        const blob = new File([response.data], filename, {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64'
        });
        FileSaver.saveAs(blob);
      }

      yield put(actions.exportSettlementsSuccess());
    } else {
      yield put(actions.exportSettlementsError());
    }
  } catch (error) {
    yield put(actions.exportSettlementsError());
  }
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.GET_ALL_SETTLEMENTS, getAllSettlementsSaga),
    takeEvery(actions.GET_SETTLEMENTS_BY_CLAIM_ID, getSettlementsByClaimIdSaga),
    takeEvery(actions.CREATE_SETTLEMENT, createSettlement),
    takeEvery(actions.UPDATE_SETTLEMENT, updateSettlement),
    takeEvery(actions.UPDATE_SETTLEMENT_RESULT, reloadSettlements),
    takeEvery(actions.UPDATE_SETTLEMENT_STATUS_RESULT, reloadSettlements),
    takeEvery(actions.CREATE_SETTLEMENT_RESULT, reloadSettlements),
    takeEvery(actions.LOAD_CUTBACK_REASONS, getSettlementInvoiceCutbackReasons),
    takeEvery(actions.CREATE_CUTBACK_REASON, createSettlementCutbackReason),
    takeEvery(actions.DOWNLOAD_SETTLEMENT_PDF_SUMMARY, loadPDFSettlementSummary),
    takeEvery(actions.EXPORT_SETTLEMENTS, exportSettlementsSaga)
  ]);
}
