import { all, takeEvery, put, select, call } from 'redux-saga/effects';
import {
  onLoadAll,
  onCreateOrganization,
  onUpdateOrganization,
  onLoadExternalContacts,
  onCreateExternalContacts,
  onUpdateExternalContacts,
  onDeleteExternalContacts,
  onLoadOne,
  onGetMessageTemplates,
  onCreateMessageTemplate,
  onUpdateMessageTemplate,
  onDeleteMessageTemplate,
  onGetPredefinedMessageTemplates
} from './apiCalls';
import actions from './actions';
import renderNotification from '../../helpers/notifications/renderNotification';
import { NOTIFICATION_MESSAGES } from '../../localization/Notifications/dictionary';
import usersActions from '../users/actions';

export function* loadOrganizations({ params }) {
  try {
    const response = yield call(onLoadAll, params);

    if (response.data) {
      yield put(actions.getAllOrganizationsSuccess(response.data));
    } else {
      yield put(actions.getAllOrganizationsError());
    }
  } catch (error) {
    yield put(actions.getAllOrganizationsError());
  }
}

export function* loadOneOrganization({ organizationId }) {
  try {
    const response = yield call(onLoadOne, organizationId);
    if (response.data) {
      yield put(actions.getOneOrganizationSuccess(response.data));
    } else {
      yield put(actions.getOneOrganizationError());
    }
  } catch (error) {
    yield put(actions.getOneOrganizationError());
  }
}

export function* createOrganization({ payload, callback }) {
  try {
    const response = yield call(onCreateOrganization, payload);
    if (response.data) {
      const { organization_create_success } = NOTIFICATION_MESSAGES;
      renderNotification({ type: 'success', message: organization_create_success });
      yield put(actions.createOrganizationSuccess(response.data));
      if (callback && typeof callback === 'function') {
        callback();
      }
    } else {
      yield put(actions.createOrganizationError());
    }
  } catch {
    yield put(actions.createOrganizationError());
  }
}

export function* updateOrganization({ organizationData, callback }) {
  try {
    const response = yield call(onUpdateOrganization, organizationData);
    if (response.data) {
      const { organization_update_success } = NOTIFICATION_MESSAGES;
      renderNotification({ type: 'success', message: organization_update_success });
      yield put(actions.updateOrganizationSuccess(response.data));
      if (callback && typeof callback === 'function') {
        callback();
      }
    } else {
      yield put(actions.updateOrganizationError());
    }
  } catch {
    yield put(actions.updateOrganizationError());
  }
}

export function* getExternalContacts({ organization_id }) {
  try {
    const response = yield call(onLoadExternalContacts, { organization_id });
    if (response.data) {
      yield put(actions.getExternalContactsSuccess(response.data));
    } else {
      yield put(actions.getExternalContactsError());
    }
  } catch {
    yield put(actions.getExternalContactsError());
  }
}

export function* createExternalContactsSaga({ externalContacts, callback }) {
  try {
    const response = yield call(onCreateExternalContacts, externalContacts);
    if (response.data) {
      const { external_contact_create_success } = NOTIFICATION_MESSAGES;
      renderNotification({ type: 'success', message: external_contact_create_success });
      yield put(actions.createExternalContactsSuccess(response.data));
      if (callback && typeof callback === 'function') {
        callback();
      }
    } else {
      yield put(actions.createExternalContactsError());
    }
  } catch {
    yield put(actions.createExternalContactsError());
  }
}

export function* updateExternalContactsSaga({ externalContacts, callback }) {
  try {
    const response = yield call(onUpdateExternalContacts, externalContacts);
    if (response.status === 200) {
      const { external_contact_update_success } = NOTIFICATION_MESSAGES;
      renderNotification({ type: 'success', message: external_contact_update_success });
      yield put(actions.updateExternalContactsSuccess(response.data));
      if (callback && typeof callback === 'function') {
        callback();
      }
    } else {
      yield put(actions.updateExternalContactsError());
    }
  } catch {
    yield put(actions.updateExternalContactsError());
  }
}

export function* deleteExternalContactsSaga({ externalContactsIds }) {
  try {
    const response = yield call(onDeleteExternalContacts, externalContactsIds);
    if (response.status === 200) {
      const { external_contact_delete_success } = NOTIFICATION_MESSAGES;
      renderNotification({ type: 'success', message: external_contact_delete_success });
      yield put(actions.deleteExternalContactsSuccess());
    } else {
      yield put(actions.deleteExternalContactsError());
    }
  } catch {
    yield put(actions.deleteExternalContactsError());
  }
}

export function* getMessageTemplates({ params }) {
  try {
    const messageTemplates = yield call(onGetMessageTemplates, params);
    if (messageTemplates.data) {
      yield put(actions.getMessageTemplatesSuccess(messageTemplates.data));
    } else {
      yield put(actions.getMessageTemplatesError());
    }
  } catch {
    yield put(actions.getMessageTemplatesError());
  }
}

export function* getPredefinedMessageTemplates() {
  try {
    const messageTemplates = yield call(onGetPredefinedMessageTemplates);
    if (messageTemplates.data) {
      yield put(actions.getPredefinedMessageTemplatesSuccess(messageTemplates.data));
    } else {
      yield put(actions.getPredefinedMessageTemplatesError());
    }
  } catch {
    yield put(actions.getPredefinedMessageTemplatesError());
  }
}

export function* createMessageTemplate({ organization_id, name, text, callback }) {
  try {
    const organizationData = {
      organization_id,
      name,
      text
    };
    const messageTemplates = yield call(onCreateMessageTemplate, organizationData);
    if (messageTemplates.status === 200) {
      const { message_template_create_success } = NOTIFICATION_MESSAGES;
      renderNotification({ type: 'success', message: message_template_create_success });
      yield put(actions.createMessageTemplateSuccess());
      if (callback && typeof callback === 'function') {
        callback();
      }
    } else {
      yield put(actions.createMessageTemplateError());
    }
  } catch {
    yield put(actions.createMessageTemplateError());
  }
}

export function* updateMessageTemplate({ payload, callback }) {
  try {
    const messageTemplates = yield call(onUpdateMessageTemplate, payload);
    if (messageTemplates.status === 200) {
      const { message_template_update_success } = NOTIFICATION_MESSAGES;
      renderNotification({ type: 'success', message: message_template_update_success });
      yield put(actions.updateMessageTemplateSuccess());
      if (callback && typeof callback === 'function') {
        callback();
      }
    } else {
      yield put(actions.updateMessageTemplateError());
    }
  } catch {
    yield put(actions.updateMessageTemplateError());
  }
}

export function* deleteMessageTemplate({ id }) {
  try {
    const messageTemplates = yield call(onDeleteMessageTemplate, id);
    if (messageTemplates.status === 200) {
      const { message_template_delete_success } = NOTIFICATION_MESSAGES;
      renderNotification({ type: 'success', message: message_template_delete_success });
      yield put(actions.deleteMessageTemplateSuccess());
    } else {
      yield put(actions.deleteMessageTemplateError());
    }
  } catch {
    yield put(actions.deleteMessageTemplateError());
  }
}

export function* setSelectedOrganizationSaga({ organization }) {
  yield put(actions.setSelectedOrganizationSuccess(organization));
}

const getUserRights = (state) => state.Auth.get('userRights');

export function* setSelectedOrganizationSuccessSaga({ organization }) {
  if (!organization) {
    return;
  }
  yield put(usersActions.getUsersByOrganization(organization.id));
  const templateParams = {
    organization_id: organization.id,
    paging: false
  };
  yield put(actions.getMessageTemplates(templateParams));

  const userRights = yield select(getUserRights);
  if (userRights.administration_external_contacts_ui) {
    yield put(actions.getExternalContacts(organization.id));
  }
}

const getSelectedOrganization = (state) => state.Organizations.get('selectedOrganization');

function* reloadExternalContacts() {
  const organization = yield select(getSelectedOrganization);
  yield put(actions.getExternalContacts(organization.id));
}

function* reloadMessageTemplates() {
  const organization = yield select(getSelectedOrganization);
  const templateParams = {
    organization_id: organization.id,
    paging: false
  };
  yield put(actions.getMessageTemplates(templateParams));
}

function* reloadAllOrganizationsAndUpdateSelectedOrganization({ newSelectedOrganization }) {
  yield put(actions.getAllOrganizations());
  yield put(actions.setSelectedOrganization(newSelectedOrganization));
}

export default function* rootSaga() {
  yield all([
    takeEvery(actions.LOAD_ORGANIZATIONS, loadOrganizations),
    takeEvery(actions.LOAD_ORGANIZATION_ONE, loadOneOrganization),
    takeEvery(actions.CREATE_ORGANIZATION, createOrganization),
    takeEvery(actions.CREATE_ORGANIZATION_SUCCESS, reloadAllOrganizationsAndUpdateSelectedOrganization),
    takeEvery(actions.UPDATE_ORGANIZATION, updateOrganization),
    takeEvery(actions.UPDATE_ORGANIZATION_SUCCESS, reloadAllOrganizationsAndUpdateSelectedOrganization),
    takeEvery(actions.GET_EXTERNAL_CONTACTS, getExternalContacts),
    takeEvery(actions.UPDATE_EXTERNAL_CONTACTS, updateExternalContactsSaga),
    takeEvery(actions.UPDATE_EXTERNAL_CONTACTS_SUCCESS, reloadExternalContacts),
    takeEvery(actions.CREATE_EXTERNAL_CONTACTS, createExternalContactsSaga),
    takeEvery(actions.CREATE_EXTERNAL_CONTACTS_SUCCESS, reloadExternalContacts),
    takeEvery(actions.DELETE_EXTERNAL_CONTACTS, deleteExternalContactsSaga),
    takeEvery(actions.DELETE_EXTERNAL_CONTACTS_SUCCESS, reloadExternalContacts),
    takeEvery(actions.SET_SELECTED_ORGANIZATION, setSelectedOrganizationSaga),
    takeEvery(actions.SET_SELECTED_ORGANIZATION_SUCCESS, setSelectedOrganizationSuccessSaga),
    takeEvery(actions.GET_MESSAGE_TEMPLATES, getMessageTemplates),
    takeEvery(actions.CREATE_MESSAGE_TEMPLATE, createMessageTemplate),
    takeEvery(actions.CREATE_MESSAGE_TEMPLATE_SUCCESS, reloadMessageTemplates),
    takeEvery(actions.UPDATE_MESSAGE_TEMPLATE, updateMessageTemplate),
    takeEvery(actions.UPDATE_MESSAGE_TEMPLATE_SUCCESS, reloadMessageTemplates),
    takeEvery(actions.DELETE_MESSAGE_TEMPLATE, deleteMessageTemplate),
    takeEvery(actions.DELETE_MESSAGE_TEMPLATE_SUCCESS, reloadMessageTemplates),
    takeEvery(actions.GET_PREDEFINED_MESSAGE_TEMPLATES, getPredefinedMessageTemplates)
  ]);
}
