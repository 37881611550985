import styled from 'styled-components';
import { IconClose, IconFilter } from '../Icons';
import { windowMaxWidth } from './breakpoints';
import { Heading3 } from '../Heading/Heading';

const FiltersHeader = styled.div`
  border-bottom: solid 1px var(--greyscale-dark-white);
  display: flex;
  justify-content: space-between;
  padding: 22px 20px;
`;

const FiltersHeaderLabelWrapper = styled.div`
  align-items: center;
  display: flex;
`;

const FiltersHeaderLabel = styled(Heading3)`
  margin: 0 0 0 10px;
`;

const FiltersHeaderCloseButtonWrapper = styled.div`
  display: none;

  svg {
    path {
      stroke: #111 !important;
    }
  }

  @media ${windowMaxWidth.splitScreen} {
    display: block;
  }
`;

const FiltersActiveBubble = styled.div`
  background: var(--primary-oqio-blue);
  border-radius: var(--border-radius-circle);
  height: 10px;
  opacity: 1;
  position: absolute;
  right: -3px;
  top: 5px;
  width: 10px;
`;

const FiltersSectionWrapper = styled.div`
  background-color: var(--greyscale-white);
  border: solid 1px var(--greyscale-dark-white);
  border-radius: var(--border-radius-regular);
  width: 260px;
`;

const Filters = styled.div`
  height: calc(100vh - 303px);
  overflow: auto;
  padding: 0 20px;
  scrollbar-color: rgba(136, 136, 136, 0.3) transparent;
  scrollbar-width: thin;

  &.map-view {
    height: calc(100vh - 254px);
  }

  @media ${windowMaxWidth.splitScreen} {
    height: calc(100vh - 171px);
  }
`;

const FilterSection = styled.div`
  display: block;
`;

const FilterLabelListWrapper = styled.ul`
  margin: 0;
  padding: 0;
`;

const FilterLabelWrapper = styled.li`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 2px;

  &:last-child {
    margin-bottom: 0;
  }
`;

const FilterModalWrapper = styled.div``;

const FilterModalRangeSeparator = styled.span`
  display: flex;
  margin: 10px;
`;

const FilterModalDescription = styled.div`
  color: var(--greyscale-blackish);
  font-family: var(--font-family-regular);
  font-size: var(--font-size-regular);
  line-height: var(--font-line-height-regular);
  margin-bottom: 16px;
`;

const FilterModalAmountRangeFieldsWrapper = styled.div`
  align-items: flex-end;
  display: flex;
`;

const FilterModalDateRangeFieldsWrapper = styled.div`
  align-items: flex-end;
  display: flex;
  margin-bottom: 23px;
`;

const FilterModalFieldWrapper = styled.div`
  width: 100%;
`;

const FilterModalFieldLabel = styled.div`
  color: var(--greyscale-shade);
  font-size: var(--font-size-small);
  height: 20px;
  line-height: var(--font-line-height-small);
  margin-left: 13px;
`;

const FilterModalErrorMessage = styled.span`
  bottom: 0px;
  color: #f5222d;
  font-size: var(--font-size-small);
  line-height: var(--font-line-height-small);
  opacity: ${(props) => (props.$hasError ? 1 : 0)};
  transition: opacity 200ms;
`;

const FilterModalCheckboxGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  padding: 20px 40px;
  border-bottom: 1px solid var(--greyscale-dark-white);

  &:last-child {
    border-bottom: none;
  }

  .group-main-checkbox {
    margin-right: 10px;
  }

  .group-element-checkbox {
    margin-left: 20px;
  }
`;

/**
 * Object containing styled-components for the layout of our Filters.
 * @typedef {object}
 * @property {Component} Header - Styled-component for Header block of the filter
 * @property {Component} HeaderLabel - Styled-component for Label for the header
 * @property {Component} IconFilter - SVG Icon for the Filters
 * @property {Component} FiltersSectionWrapper - Styled-component of the wrapper for the filters
 * @property {Component} Filters - Styled-component for the filters
 * @property {Component} FilterSection - Styled-component for the filter section
 */
const FilterLayout = {
  FiltersHeader,
  FiltersHeaderLabelWrapper,
  FiltersHeaderLabel,
  FiltersHeaderCloseButtonWrapper,
  FiltersActiveBubble,
  IconClose,
  IconFilter,
  FiltersSectionWrapper,
  Filters,
  FilterSection,
  FilterLabelListWrapper,
  FilterLabelWrapper,
  FilterModalWrapper,
  FilterModalRangeSeparator,
  FilterModalDescription,
  FilterModalAmountRangeFieldsWrapper,
  FilterModalDateRangeFieldsWrapper,
  FilterModalFieldWrapper,
  FilterModalFieldLabel,
  FilterModalErrorMessage,
  FilterModalCheckboxGroupWrapper
};

export default FilterLayout;
